.carouselonboarding {
  height: 600px;
  position: relative;
  width: 1207px;
}

.carouselonboarding .arrow-right {
  height: 56px;
  left: 1151px;
  position: absolute;
  top: 287px;
  width: 56px;
}

.carouselonboarding .img {
  height: 56px;
  left: 0;
  position: absolute;
  top: 287px;
  width: 56px;
}

.carouselonboarding .overlap-group {
  height: 601px;
  left: 124px;
  position: absolute;
  top: -1px;
  width: 983px;
}

.carouselonboarding .element {
  color: var(--ecogreen-600);
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 600px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 600px;
  opacity: 0.16;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.carouselonboarding .frame {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 0;
  position: absolute;
  top: 209px;
}

.carouselonboarding .join-the-tannit {
  color: var(--ecogreen-1000);
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 44px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 44px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.carouselonboarding .sign-up-for-a-free {
  color: var(--ecogreen-900);
  font-family: "Kufam", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 44.8px;
  position: relative;
  text-align: center;
  width: 983px;
}

.carouselonboarding .div {
  align-items: flex-start;
  display: inline-flex;
  gap: 8px;
  left: 435px;
  position: absolute;
  top: 585px;
}

.carouselonboarding .ellipse {
  border-radius: 8px;
  height: 16px;
  position: relative;
  width: 16px;
}

.carouselonboarding .ellipse-2 {
  border-radius: 8px;
  height: 16px;
  position: relative;
  width: 16px;
}

.carouselonboarding .ellipse-3 {
  border-radius: 8px;
  height: 16px;
  position: relative;
  width: 16px;
}

.carouselonboarding .ellipse-4 {
  border-radius: 8px;
  height: 16px;
  position: relative;
  width: 16px;
}

.carouselonboarding .variant-4 {
  left: 187px;
}

.carouselonboarding .default {
  left: 268px;
}

.carouselonboarding .variant-3 {
  left: 206px;
}

.carouselonboarding .variant-2 {
  left: 201px;
}

.carouselonboarding .property-1-variant-4 {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-default {
  background-color: var(--ecogreen-600);
}

.carouselonboarding .property-1-variant-3 {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-variant-2 {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-0-variant-4 {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-0-default {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-0-variant-3 {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-0-variant-2 {
  background-color: var(--ecogreen-600);
}

.carouselonboarding .property-1-1-variant-4 {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-1-default {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-1-variant-3 {
  background-color: var(--ecogreen-600);
}

.carouselonboarding .property-1-1-variant-2 {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-2-variant-4 {
  background-color: var(--ecogreen-600);
}

.carouselonboarding .property-1-2-default {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-2-variant-3 {
  background-color: var(--ecogreen-400);
}

.carouselonboarding .property-1-2-variant-2 {
  background-color: var(--ecogreen-400);
}
