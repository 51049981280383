.welcome-screen {
  background-color: var(--graymain-10);
  position: relative;
  max-width: 1980px !important;
}

.welcome-screen .overlap {
  height: 1024px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100% !important;
}

.welcome-screen .map-container {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgb(255, 255, 255) 0%,
    rgb(22, 38, 13) 100%
  );
  height: 1024px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100% !important;
}

.welcome-screen .overlap-group {
  height: 3000px;
  left: -780px;
  position: relative;
  top: -988px;
  width: 3000px;
}

.welcome-screen .map-sizes {
  height: 3000px;
  left: 0;
  position: absolute;
  top: 0;
  width: 3000px;
}

.welcome-screen .snazzy-image {
  height: 2812px;
  left: 0;
  position: absolute;
  top: 0;
  width: 3000px;
  background-image: url(/public/assets/images/ecovestwelcomeimg/snazzy-image-2-1.png);
}

.welcome-screen .trees {
  height: 549px;
  left: 1206px;
  position: absolute;
  top: 1185px;
  width: 671px;
}

.welcome-screen .tree-instance {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-43.png) !important;
  height: 37px !important;
  left: 400px !important;
  position: absolute !important;
  top: 23px !important;
  width: 22px !important;
}

.welcome-screen .design-component-instance-node {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-35.png) !important;
  height: 36px !important;
  left: 156px !important;
  position: absolute !important;
  top: 42px !important;
  width: 21px !important;
}

.welcome-screen .tree-2 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-22.png) !important;
  height: 37px !important;
  left: 160px !important;
  position: absolute !important;
  top: 299px !important;
  width: 22px !important;
}

.welcome-screen .tree-3 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-42.png) !important;
  height: 36px !important;
  left: 379px !important;
  position: absolute !important;
  top: 148px !important;
  width: 22px !important;
}

.welcome-screen .tree-4 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-43.png) !important;
  height: 37px !important;
  left: 6px !important;
  position: absolute !important;
  top: 286px !important;
  width: 22px !important;
}

.welcome-screen .tree-5 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-42.png) !important;
  height: 36px !important;
  left: 199px !important;
  position: absolute !important;
  top: 137px !important;
  width: 22px !important;
}

.welcome-screen .tree-6 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-43.png) !important;
  height: 37px !important;
  left: 229px !important;
  position: absolute !important;
  top: 391px !important;
  width: 22px !important;
}

.welcome-screen .tree-7 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-43.png) !important;
  height: 37px !important;
  left: 60px !important;
  position: absolute !important;
  top: 417px !important;
  width: 22px !important;
}

.welcome-screen .tree-8 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-42.png) !important;
  height: 36px !important;
  left: 56px !important;
  position: absolute !important;
  top: 356px !important;
  width: 22px !important;
}

.welcome-screen .tree-9 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-42.png) !important;
  height: 36px !important;
  left: 240px !important;
  position: absolute !important;
  top: 198px !important;
  width: 22px !important;
}

.welcome-screen .tree-10 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-42.png) !important;
  height: 36px !important;
  left: 6px !important;
  position: absolute !important;
  top: 200px !important;
  width: 22px !important;
}

.welcome-screen .tree-11 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-43.png) !important;
  height: 37px !important;
  left: 199px !important;
  position: absolute !important;
  top: 50px !important;
  width: 22px !important;
}

.welcome-screen .tree-12 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-22.png) !important;
  height: 37px !important;
  left: 240px !important;
  position: absolute !important;
  top: 315px !important;
  width: 22px !important;
}

.welcome-screen .tree-13 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-35.png) !important;
  height: 36px !important;
  left: 304px !important;
  position: absolute !important;
  top: 175px !important;
  width: 21px !important;
}

.welcome-screen .tree-14 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-32.png) !important;
  height: 37px !important;
  left: 464px !important;
  position: absolute !important;
  top: 45px !important;
  width: 21px !important;
}

.welcome-screen .tree-15 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-35.png) !important;
  height: 36px !important;
  left: 139px !important;
  position: absolute !important;
  top: 122px !important;
  width: 21px !important;
}

.welcome-screen .tree-16 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-42.png) !important;
  height: 36px !important;
  left: 168px !important;
  position: absolute !important;
  top: 377px !important;
  width: 22px !important;
}

.welcome-screen .tree-17 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-42.png) !important;
  height: 36px !important;
  left: 622px !important;
  position: absolute !important;
  top: 19px !important;
  width: 22px !important;
}

.welcome-screen .tree-18 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-42.png) !important;
  height: 36px !important;
  left: 70px !important;
  position: absolute !important;
  top: 212px !important;
  width: 22px !important;
}

.welcome-screen .tree-19 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-42.png) !important;
  height: 36px !important;
  left: 263px !important;
  position: absolute !important;
  top: 59px !important;
  width: 22px !important;
}

.welcome-screen .tree-20 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-22.png) !important;
  height: 37px !important;
  left: 269px !important;
  position: absolute !important;
  top: 442px !important;
  width: 22px !important;
}

.welcome-screen .tree-21 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-35.png) !important;
  height: 36px !important;
  left: 519px !important;
  position: absolute !important;
  top: 76px !important;
  width: 21px !important;
}

.welcome-screen .tree-22 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-43.png) !important;
  height: 37px !important;
  left: 56px !important;
  position: absolute !important;
  top: 150px !important;
  width: 22px !important;
}

.welcome-screen .tree-23 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-35.png) !important;
  height: 36px !important;
  left: 249px !important;
  position: absolute !important;
  top: 0 !important;
  width: 21px !important;
}

.welcome-screen .tree-24 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-43.png) !important;
  height: 37px !important;
  left: 278px !important;
  position: absolute !important;
  top: 255px !important;
  width: 22px !important;
}

.welcome-screen .tree-25 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-35.png) !important;
  height: 36px !important;
  left: 97px !important;
  position: absolute !important;
  top: 464px !important;
  width: 21px !important;
}

.welcome-screen .tree-26 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-43.png) !important;
  height: 37px !important;
  left: 101px !important;
  position: absolute !important;
  top: 151px !important;
  width: 22px !important;
}

.welcome-screen .tree-27 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-35.png) !important;
  height: 36px !important;
  left: 295px !important;
  position: absolute !important;
  top: 1px !important;
  width: 21px !important;
}

.welcome-screen .tree-28 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-42.png) !important;
  height: 36px !important;
  left: 327px !important;
  position: absolute !important;
  top: 213px !important;
  width: 22px !important;
}

.welcome-screen .tree-29 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-42.png) !important;
  height: 36px !important;
  left: 550px !important;
  position: absolute !important;
  top: 19px !important;
  width: 22px !important;
}

.welcome-screen .tree-30 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-32.png) !important;
  height: 37px !important;
  left: 97px !important;
  position: absolute !important;
  top: 282px !important;
  width: 21px !important;
}

.welcome-screen .tree-31 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-43.png) !important;
  height: 37px !important;
  left: 282px !important;
  position: absolute !important;
  top: 134px !important;
  width: 22px !important;
}

.welcome-screen .tree-32 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-42.png) !important;
  height: 36px !important;
  left: 312px !important;
  position: absolute !important;
  top: 391px !important;
  width: 22px !important;
}

.welcome-screen .tree-33 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-35.png) !important;
  height: 36px !important;
  left: 143px !important;
  position: absolute !important;
  top: 414px !important;
  width: 21px !important;
}

.welcome-screen .tree-34 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-36.png) !important;
  height: 36px !important;
  left: 145px !important;
  position: absolute !important;
  top: 238px !important;
  width: 22px !important;
}

.welcome-screen .tree-35 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-43.png) !important;
  height: 37px !important;
  left: 333px !important;
  position: absolute !important;
  top: 82px !important;
  width: 22px !important;
}

.welcome-screen .tree-36 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-42.png) !important;
  height: 36px !important;
  left: 0 !important;
  position: absolute !important;
  top: 513px !important;
  width: 22px !important;
}

.welcome-screen .tree-37 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-43.png) !important;
  height: 37px !important;
  left: 588px !important;
  position: absolute !important;
  top: 99px !important;
  width: 22px !important;
}

.welcome-screen .tree-38 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-42.png) !important;
  height: 36px !important;
  left: 200px !important;
  position: absolute !important;
  top: 233px !important;
  width: 22px !important;
}

.welcome-screen .tree-39 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-43.png) !important;
  height: 37px !important;
  left: 392px !important;
  position: absolute !important;
  top: 82px !important;
  width: 22px !important;
}

.welcome-screen .tree-40 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-42.png) !important;
  height: 36px !important;
  left: 61px !important;
  position: absolute !important;
  top: 513px !important;
  width: 22px !important;
}

.welcome-screen .tree-41 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/image-85-43.png) !important;
  height: 37px !important;
  left: 649px !important;
  position: absolute !important;
  top: 99px !important;
  width: 22px !important;
}

.welcome-screen .filter {
  align-items: center;
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--graymain-20);
  border-radius: 8px;
  display: inline-flex;
  gap: 8px;
  height: 32px;
  justify-content: center;
  left: 2059px;
  overflow: hidden;
  padding: 8px;
  position: absolute;
  top: 1844px;
}

.welcome-screen .div {
  color: var(--ecogreen-1000);
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.welcome-screen .frame {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  left: 824px;
  position: absolute;
  top: 1916px;
  width: 1630px;
}

.welcome-screen .table-row {
  -webkit-backdrop-filter: blur(14.4px) brightness(100%);
  align-items: center;
  backdrop-filter: blur(14.4px) brightness(100%);
  background-color: #f7f7f7;
  border-radius: 8px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  justify-content: center;
  padding: 8px;
  position: relative;
}

.welcome-screen .frame-2 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/frame-34119.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 8px;
  height: 46px;
  position: relative;
  width: 46px;
}

.welcome-screen .frame-3 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  position: relative;
}

.welcome-screen .user {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.welcome-screen .text-wrapper-2 {
  color: var(--ecogreen-1000);
  flex: 1;
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
}

.welcome-screen .div-wrapper {
  align-items: center;
  background-color: var(--ecogreen-200);
  border-radius: 22px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 0px 8px;
  position: relative;
}

.welcome-screen .text-wrapper-3 {
  color: var(--ecogreen-900);
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.welcome-screen .text-wrapper-4 {
  align-self: stretch;
  color: var(--ecogreen-1000);
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.welcome-screen .frame-4 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/frame-34119-1.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 8px;
  height: 46px;
  position: relative;
  width: 46px;
}

.welcome-screen .frame-5 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/frame-34119-2.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 8px;
  height: 46px;
  position: relative;
  width: 46px;
}

.welcome-screen .frame-6 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/frame-34119-3.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 8px;
  height: 46px;
  position: relative;
  width: 46px;
}

.welcome-screen .frame-7 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/frame-34119-4.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 8px;
  height: 46px;
  position: relative;
  width: 46px;
}

.welcome-screen .frame-8 {
  background-image: url(/public/assets/images/ecovestwelcomeimg/frame-34119-5.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 8px;
  height: 46px;
  position: relative;
  width: 46px;
}

.welcome-screen .button {
  align-items: center;
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--graymain-20);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  height: 72px;
  justify-content: space-between;
  left: 2140px;
  overflow: hidden;
  padding: 16px 8px;
  position: absolute;
  top: 1755px;
  width: 40px;
}

.welcome-screen .icons-font-awesome-instance {
  color: var(--ecogreen-1000) !important;
}

.welcome-screen .divider {
  align-self: stretch;
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.welcome-screen .p {
  color: var(--basewhite);
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 1824px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 1433px;
  white-space: nowrap;
}

.welcome-screen .text-wrapper-5 {
  color: var(--ecogreen-50);
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 1828px;
  letter-spacing: 5.76px;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 1463px;
  white-space: nowrap;
}

.welcome-screen .earn-XP {
  background-color: var(--basewhite);
  border: 0.29px solid;
  border-color: var(--graymain-20);
  border-radius: 27.58px;
  box-shadow: 6.89px 6.89px 6.89px #9ecf3624;
  height: 36px;
  left: 1859px;
  position: absolute;
  top: 1029px;
  width: 124px;
}

.welcome-screen .icons-font-awesome-2 {
  left: 24px !important;
  position: absolute !important;
  top: 8px !important;
}

.welcome-screen .icons-font-awesome-3 {
  color: var(--ecogreen-1000) !important;
  font-family: "Font Awesome 6 Pro-Light", Helvetica !important;
  font-weight: 300 !important;
}

.welcome-screen .text-wrapper-6 {
  color: var(--ecogreen-1000);
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 54px;
  letter-spacing: 0;
  line-height: 12px;
  position: absolute;
  text-align: center;
  top: 12px;
  white-space: nowrap;
}

.welcome-screen .notif-breathing-animation {
  border-radius: 4px !important;
  height: 8px !important;
  left: 114px !important;
  position: absolute !important;
  top: 2px !important;
  width: 8px !important;
}

.welcome-screen .current-XP {
  background-color: var(--basewhite);
  border: 0.29px solid;
  border-color: var(--graymain-20);
  border-radius: 27.58px;
  box-shadow: 6.89px 6.89px 6.89px #9ecf3624;
  height: 36px;
  left: 1731px;
  position: absolute;
  top: 1029px;
  width: 124px;
}

.welcome-screen .green-coin {
  height: 20px;
  left: 19px;
  position: absolute;
  top: 8px;
  width: 20px;
}

.welcome-screen .leaderboard {
  background-color: var(--basewhite);
  border: 0.29px solid;
  border-color: var(--graymain-20);
  border-radius: 27.58px;
  box-shadow: 6.89px 6.89px 6.89px #9ecf3624;
  height: 36px;
  left: 1987px;
  position: absolute;
  top: 1029px;
  width: 144px;
}

.welcome-screen .text-wrapper-7 {
  color: var(--ecogreen-1000);
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 50px;
  letter-spacing: 0;
  line-height: 12px;
  position: absolute;
  text-align: center;
  top: 12px;
  white-space: nowrap;
}

.welcome-screen .tour-again {
  align-items: center;
  background-color: var(--basewhite);
  border: 0.29px solid;
  border-color: var(--graymain-20);
  border-radius: 27.58px;
  box-shadow: 6.89px 6.89px 6.89px #9ecf3624;
  display: inline-flex;
  height: 36px;
  left: 2135px;
  padding: 8px;
  position: absolute;
  top: 1029px;
}

.welcome-screen .go-back-button {
  background-color: var(--basewhite);
  border: 0.29px solid;
  border-color: var(--graymain-20);
  border-radius: 27.58px;
  box-shadow: 6.89px 6.89px 6.89px #9ecf3624;
  height: 52px;
  left: 48px;
  position: absolute;
  top: 24px;
  width: 202px;
}

.welcome-screen .icons-font-awesome-4 {
  left: 16px !important;
  position: absolute !important;
  top: 18px !important;
}

.welcome-screen .icons-font-awesome-5 {
  color: var(--brand-colorsdeep-koamaru) !important;
}

.welcome-screen .logo {
  height: 32px;
  left: 40px;
  position: absolute;
  top: 10px;
  width: 138px;
}

.welcome-screen .text {
  color: var(--graymain-300);
  font-family: var(--body-large-font-family);
  font-size: var(--body-large-font-size);
  font-style: var(--body-large-font-style);
  font-weight: var(--body-large-font-weight);
  left: 186px;
  letter-spacing: var(--body-large-letter-spacing);
  line-height: var(--body-large-line-height);
  position: absolute;
  top: 14px;
  white-space: nowrap;
}

.welcome-screen .button-2 {
  align-items: center;
  background-color: var(--ecogreen-500);
  border-radius: 180px;
  box-shadow: 6.89px 6.89px 6.89px #9ecf3624;
  display: inline-flex;
  gap: 8px;
  height: 46px;
  justify-content: center;
  padding: 12px 16px;
  position: fixed;
  top: 35px;
}

.welcome-screen .text-wrapper-8 {
  color: var(--basewhite);
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.welcome-screen .instance-tooltip-instance {
  flex: 0 0 auto !important;
  margin-bottom: -5.00px !important;
  margin-top: -5.00px !important;
}

.welcome-screen .planting-tree-available-trees {
  height: 32px !important;
}

.welcome-screen .instance-tooltip-2 {
  left: 186px !important;
}

.welcome-screen .instance-tooltip-3 {
  left: -172px !important;
  opacity: 0 !important;
  position: absolute !important;
  top: 24px !important;
}

.welcome-screen .container {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 40px;
  position: fixed;
  top: 80px;
}

.welcome-screen .city-info {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.welcome-screen .city-name {
  color: var(--basewhite);
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 40px;
  margin-top: -1.00px;
  opacity: 0.2;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.welcome-screen .trees-planted {
  color: var(--ecogreen-50);
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.64px;
  line-height: 16px;
  opacity: 0.6;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.welcome-screen .city-name-2 {
  color: var(--basewhite);
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 40px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.welcome-screen .trees-planted-2 {
  color: var(--ecogreen-50);
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.64px;
  line-height: 16px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.welcome-screen .city-name-3 {
  color: var(--basewhite);
  font-family: "Enthalpy 298-Regular", Helvetica;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 40px;
  margin-top: -1.00px;
  opacity: 0.3;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
