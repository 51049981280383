.base-swap-this {
  align-items: center;
  background-color: #ffffff;
  display: inline-flex;
  gap: 8px;
  height: 32px;
  justify-content: center;
  padding: 8px;
  position: relative;
}

.base-swap-this .change-this {
  color: #242424;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.50px;
  position: relative;
  text-align: center;
  width: fit-content;
}
