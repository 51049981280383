.planting-tree-screen {
  background-color: var(--graymain-10);
  position: relative;
  max-width: 1980px !important;
}

.planting-tree-screen .overlap {
  height: 1024px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100% !important;
}

.planting-tree-screen .map {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgb(148.83, 208.25, 115.41) 58.15%,
    rgb(131.94, 177.44, 105.72) 100%
  );
  background-image: url(../../../public/assets/images/ecovestplantingtreeimg/map.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 1024px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100% !important;
}

.planting-tree-screen .overlap-group {
  height: 1054px;
  position: relative;
}

.planting-tree-screen .trees {
  height: 666px;
  left: 325px;
  position: absolute;
  top: 140px;
  width: 80% !important;
}

.planting-tree-screen .tree-instance {
  left: 494px !important;
  position: absolute !important;
  top: 28px !important;
}

.planting-tree-screen .design-component-instance-node {
  left: 192px !important;
  position: absolute !important;
  top: 51px !important;
}

.planting-tree-screen .tree-2 {
  left: 198px !important;
  position: absolute !important;
  top: 369px !important;
}

.planting-tree-screen .tree-3 {
  left: 468px !important;
  position: absolute !important;
  top: 182px !important;
}

.planting-tree-screen .tree-4 {
  left: 7px !important;
  position: absolute !important;
  top: 353px !important;
}

.planting-tree-screen .tree-5 {
  left: 246px !important;
  position: absolute !important;
  top: 168px !important;
}

.planting-tree-screen .tree-6 {
  left: 283px !important;
  position: absolute !important;
  top: 483px !important;
}

.planting-tree-screen .tree-7 {
  left: 74px !important;
  position: absolute !important;
  top: 515px !important;
}

.planting-tree-screen .tree-8 {
  left: 69px !important;
  position: absolute !important;
  top: 439px !important;
}

.planting-tree-screen .tree-9 {
  left: 296px !important;
  position: absolute !important;
  top: 244px !important;
}

.planting-tree-screen .tree-10 {
  left: 7px !important;
  position: absolute !important;
  top: 246px !important;
}

.planting-tree-screen .tree-11 {
  left: 246px !important;
  position: absolute !important;
  top: 61px !important;
}

.planting-tree-screen .tree-12 {
  left: 296px !important;
  position: absolute !important;
  top: 389px !important;
}

.planting-tree-screen .tree-13 {
  left: 375px !important;
  position: absolute !important;
  top: 215px !important;
}

.planting-tree-screen .tree-14 {
  left: 573px !important;
  position: absolute !important;
  top: 56px !important;
}

.planting-tree-screen .tree-15 {
  left: 171px !important;
  position: absolute !important;
  top: 150px !important;
}

.planting-tree-screen .tree-16 {
  left: 207px !important;
  position: absolute !important;
  top: 465px !important;
}

.planting-tree-screen .tree-17 {
  left: 769px !important;
  position: absolute !important;
  top: 22px !important;
}

.planting-tree-screen .tree-18 {
  left: 86px !important;
  position: absolute !important;
  top: 261px !important;
}

.planting-tree-screen .tree-19 {
  left: 325px !important;
  position: absolute !important;
  top: 72px !important;
}

.planting-tree-screen .tree-20 {
  left: 332px !important;
  position: absolute !important;
  top: 546px !important;
}

.planting-tree-screen .tree-21 {
  left: 641px !important;
  position: absolute !important;
  top: 93px !important;
}

.planting-tree-screen .tree-22 {
  left: 69px !important;
  position: absolute !important;
  top: 185px !important;
}

.planting-tree-screen .tree-23 {
  left: 307px !important;
  position: absolute !important;
  top: 0 !important;
}

.planting-tree-screen .tree-24 {
  left: 344px !important;
  position: absolute !important;
  top: 315px !important;
}

.planting-tree-screen .tree-25 {
  left: 119px !important;
  position: absolute !important;
  top: 573px !important;
}

.planting-tree-screen .tree-26 {
  left: 125px !important;
  position: absolute !important;
  top: 186px !important;
}

.planting-tree-screen .tree-27 {
  left: 364px !important;
  position: absolute !important;
  top: 1px !important;
}

.planting-tree-screen .tree-28 {
  left: 404px !important;
  position: absolute !important;
  top: 262px !important;
}

.planting-tree-screen .tree-29 {
  left: 680px !important;
  position: absolute !important;
  top: 22px !important;
}

.planting-tree-screen .tree-30 {
  left: 119px !important;
  position: absolute !important;
  top: 348px !important;
}

.planting-tree-screen .tree-31 {
  left: 348px !important;
  position: absolute !important;
  top: 165px !important;
}

.planting-tree-screen .tree-32 {
  left: 386px !important;
  position: absolute !important;
  top: 482px !important;
}

.planting-tree-screen .tree-33 {
  left: 176px !important;
  position: absolute !important;
  top: 511px !important;
}

.planting-tree-screen .tree-34 {
  left: 179px !important;
  position: absolute !important;
  top: 293px !important;
}

.planting-tree-screen .tree-35 {
  left: 411px !important;
  position: absolute !important;
  top: 101px !important;
}

.planting-tree-screen .tree-36 {
  left: 0 !important;
  position: absolute !important;
  top: 633px !important;
}

.planting-tree-screen .tree-37 {
  left: 727px !important;
  position: absolute !important;
  top: 122px !important;
}

.planting-tree-screen .tree-38 {
  left: 247px !important;
  position: absolute !important;
  top: 287px !important;
}

.planting-tree-screen .tree-39 {
  left: 484px !important;
  position: absolute !important;
  top: 101px !important;
}

.planting-tree-screen .tree-40 {
  left: 75px !important;
  position: absolute !important;
  top: 633px !important;
}

.planting-tree-screen .tree-41 {
  left: 802px !important;
  position: absolute !important;
  top: 122px !important;
}

.planting-tree-screen .grid {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 0px 0px;
  height: 1054px;
  left: 0;
  padding: 8px;
  position: absolute;
  top: 0;
  width: 100% !important;
}

.planting-tree-screen .planting-tree-instance {
  margin-left: -1.00px !important;
  margin-top: -1.00px !important;
}

.planting-tree-screen .planting-tree-2 {
  left: 2434px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-3 {
  margin-top: -1.00px !important;
}

.planting-tree-screen .planting-tree-4 {
  left: 2396px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-5 {
  left: 2358px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-6 {
  left: 2320px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-7 {
  left: 2282px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-8 {
  left: 2244px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-9 {
  left: 2206px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-10 {
  left: 2168px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-11 {
  left: 2130px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-12 {
  left: 2092px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-13 {
  left: 2054px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-14 {
  left: 2016px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-15 {
  left: 1978px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-16 {
  left: 1940px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-17 {
  left: 1902px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-18 {
  left: 1864px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-19 {
  left: 1826px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-20 {
  left: 1788px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-21 {
  left: 1750px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-22 {
  left: 1712px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-23 {
  left: 1674px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-24 {
  left: 1636px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-25 {
  left: 1598px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-26 {
  left: 1560px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-27 {
  left: 1522px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-28 {
  left: 1484px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-29 {
  left: 1446px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-30 {
  left: 1408px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-31 {
  left: 1370px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-32 {
  left: 1332px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-33 {
  left: 1294px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-34 {
  left: 1256px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-35 {
  left: 1218px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-36 {
  left: 1180px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-37 {
  left: 1142px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-38 {
  left: 1104px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-39 {
  left: 1066px !important;
  top: 3906px !important;
}

.planting-tree-screen .planting-tree-40 {
  margin-left: -1.00px !important;
}

.planting-tree-screen .planting-tree-41 {
  left: 2434px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-42 {
  left: 2396px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-43 {
  left: 2358px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-44 {
  left: 2320px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-45 {
  left: 2282px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-46 {
  left: 2244px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-47 {
  left: 2206px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-48 {
  left: 2168px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-49 {
  left: 2130px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-50 {
  left: 2092px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-51 {
  left: 2054px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-52 {
  left: 2016px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-53 {
  left: 1978px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-54 {
  left: 1940px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-55 {
  left: 1902px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-56 {
  left: 1864px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-57 {
  left: 1826px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-58 {
  left: 1788px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-59 {
  left: 1750px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-60 {
  left: 1712px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-61 {
  left: 1674px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-62 {
  left: 1636px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-63 {
  left: 1598px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-64 {
  left: 1560px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-65 {
  left: 1522px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-66 {
  left: 1484px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-67 {
  left: 1446px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-68 {
  left: 1408px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-69 {
  left: 1370px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-70 {
  left: 1332px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-71 {
  left: 1294px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-72 {
  left: 1256px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-73 {
  left: 1218px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-74 {
  left: 1180px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-75 {
  left: 1142px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-76 {
  left: 1104px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-77 {
  left: 1066px !important;
  top: 3869px !important;
}

.planting-tree-screen .planting-tree-78 {
  left: 2434px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-79 {
  left: 2396px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-80 {
  left: 2358px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-81 {
  left: 2320px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-82 {
  left: 2282px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-83 {
  left: 2244px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-84 {
  left: 2206px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-85 {
  left: 2168px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-86 {
  left: 2130px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-87 {
  left: 2092px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-88 {
  left: 2054px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-89 {
  left: 2016px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-90 {
  left: 1978px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-91 {
  left: 1940px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-92 {
  left: 1902px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-93 {
  left: 1864px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-94 {
  left: 1826px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-95 {
  left: 1788px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-96 {
  left: 1750px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-97 {
  left: 1712px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-98 {
  left: 1674px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-99 {
  left: 1636px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-100 {
  left: 1598px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-101 {
  left: 1560px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-102 {
  left: 1522px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-103 {
  left: 1484px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-104 {
  left: 1446px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-105 {
  left: 1408px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-106 {
  left: 1370px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-107 {
  left: 1332px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-108 {
  left: 1294px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-109 {
  left: 1256px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-110 {
  left: 1218px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-111 {
  left: 1180px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-112 {
  left: 1142px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-113 {
  left: 1104px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-114 {
  left: 1066px !important;
  top: 3832px !important;
}

.planting-tree-screen .planting-tree-115 {
  left: 2434px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-116 {
  left: 2396px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-117 {
  left: 2358px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-118 {
  left: 2320px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-119 {
  left: 2282px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-120 {
  left: 2244px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-121 {
  left: 2206px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-122 {
  left: 2168px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-123 {
  left: 2130px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-124 {
  left: 2092px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-125 {
  left: 2054px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-126 {
  left: 2016px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-127 {
  left: 1978px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-128 {
  left: 1940px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-129 {
  left: 1902px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-130 {
  left: 1864px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-131 {
  left: 1826px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-132 {
  left: 1788px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-133 {
  left: 1750px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-134 {
  left: 1712px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-135 {
  left: 1674px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-136 {
  left: 1636px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-137 {
  left: 1598px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-138 {
  left: 1560px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-139 {
  left: 1522px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-140 {
  left: 1484px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-141 {
  left: 1446px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-142 {
  left: 1408px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-143 {
  left: 1370px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-144 {
  left: 1332px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-145 {
  left: 1294px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-146 {
  left: 1256px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-147 {
  left: 1218px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-148 {
  left: 1180px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-149 {
  left: 1142px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-150 {
  left: 1104px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-151 {
  left: 1066px !important;
  top: 3795px !important;
}

.planting-tree-screen .planting-tree-152 {
  left: 2434px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-153 {
  left: 2396px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-154 {
  left: 2358px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-155 {
  left: 2320px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-156 {
  left: 2282px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-157 {
  left: 2244px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-158 {
  left: 2206px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-159 {
  left: 2168px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-160 {
  left: 2130px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-161 {
  left: 2092px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-162 {
  left: 2054px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-163 {
  left: 2016px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-164 {
  left: 1978px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-165 {
  left: 1940px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-166 {
  left: 1902px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-167 {
  left: 1864px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-168 {
  left: 1826px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-169 {
  left: 1788px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-170 {
  left: 1750px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-171 {
  left: 1712px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-172 {
  left: 1674px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-173 {
  left: 1636px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-174 {
  left: 1598px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-175 {
  left: 1560px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-176 {
  left: 1522px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-177 {
  left: 1484px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-178 {
  left: 1446px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-179 {
  left: 1408px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-180 {
  left: 1370px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-181 {
  left: 1332px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-182 {
  left: 1294px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-183 {
  left: 1256px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-184 {
  left: 1218px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-185 {
  left: 1180px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-186 {
  left: 1142px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-187 {
  left: 1104px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-188 {
  left: 1066px !important;
  top: 3758px !important;
}

.planting-tree-screen .planting-tree-189 {
  left: 2434px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-190 {
  left: 2396px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-191 {
  left: 2358px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-192 {
  left: 2320px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-193 {
  left: 2282px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-194 {
  left: 2244px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-195 {
  left: 2206px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-196 {
  left: 2168px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-197 {
  left: 2130px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-198 {
  left: 2092px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-199 {
  left: 2054px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-200 {
  left: 2016px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-201 {
  left: 1978px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-202 {
  left: 1940px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-203 {
  left: 1902px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-204 {
  left: 1864px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-205 {
  left: 1826px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-206 {
  left: 1788px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-207 {
  left: 1750px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-208 {
  left: 1712px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-209 {
  left: 1674px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-210 {
  left: 1636px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-211 {
  left: 1598px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-212 {
  left: 1560px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-213 {
  left: 1522px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-214 {
  left: 1484px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-215 {
  left: 1446px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-216 {
  left: 1408px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-217 {
  left: 1370px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-218 {
  left: 1332px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-219 {
  left: 1294px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-220 {
  left: 1256px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-221 {
  left: 1218px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-222 {
  left: 1180px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-223 {
  left: 1142px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-224 {
  left: 1104px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-225 {
  left: 1066px !important;
  top: 3721px !important;
}

.planting-tree-screen .planting-tree-226 {
  left: 2434px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-227 {
  left: 2396px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-228 {
  left: 2358px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-229 {
  left: 2320px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-230 {
  left: 2282px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-231 {
  left: 2244px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-232 {
  left: 2206px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-233 {
  left: 2168px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-234 {
  left: 2130px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-235 {
  left: 2092px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-236 {
  left: 2054px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-237 {
  left: 2016px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-238 {
  left: 1978px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-239 {
  left: 1940px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-240 {
  left: 1902px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-241 {
  left: 1864px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-242 {
  left: 1826px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-243 {
  left: 1788px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-244 {
  left: 1750px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-245 {
  left: 1712px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-246 {
  left: 1674px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-247 {
  left: 1636px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-248 {
  left: 1598px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-249 {
  left: 1560px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-250 {
  left: 1522px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-251 {
  left: 1484px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-252 {
  left: 1446px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-253 {
  left: 1408px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-254 {
  left: 1370px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-255 {
  left: 1332px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-256 {
  left: 1294px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-257 {
  left: 1256px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-258 {
  left: 1218px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-259 {
  left: 1180px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-260 {
  left: 1142px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-261 {
  left: 1104px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-262 {
  left: 1066px !important;
  top: 3684px !important;
}

.planting-tree-screen .planting-tree-263 {
  left: 2434px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-264 {
  left: 2396px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-265 {
  left: 2358px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-266 {
  left: 2320px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-267 {
  left: 2282px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-268 {
  left: 2244px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-269 {
  left: 2206px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-270 {
  left: 2168px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-271 {
  left: 2130px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-272 {
  left: 2092px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-273 {
  left: 2054px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-274 {
  left: 2016px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-275 {
  left: 1978px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-276 {
  left: 1940px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-277 {
  left: 1902px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-278 {
  left: 1864px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-279 {
  left: 1826px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-280 {
  left: 1788px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-281 {
  left: 1750px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-282 {
  left: 1712px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-283 {
  left: 1674px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-284 {
  left: 1636px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-285 {
  left: 1598px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-286 {
  left: 1560px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-287 {
  left: 1522px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-288 {
  left: 1484px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-289 {
  left: 1446px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-290 {
  left: 1408px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-291 {
  left: 1370px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-292 {
  left: 1332px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-293 {
  left: 1294px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-294 {
  left: 1256px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-295 {
  left: 1218px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-296 {
  left: 1180px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-297 {
  left: 1142px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-298 {
  left: 1104px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-299 {
  left: 1066px !important;
  top: 3647px !important;
}

.planting-tree-screen .planting-tree-300 {
  left: 2434px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-301 {
  left: 2396px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-302 {
  left: 2358px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-303 {
  left: 2320px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-304 {
  left: 2282px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-305 {
  left: 2244px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-306 {
  left: 2206px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-307 {
  left: 2168px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-308 {
  left: 2130px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-309 {
  left: 2092px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-310 {
  left: 2054px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-311 {
  left: 2016px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-312 {
  left: 1978px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-313 {
  left: 1940px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-314 {
  left: 1902px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-315 {
  left: 1864px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-316 {
  left: 1826px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-317 {
  left: 1788px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-318 {
  left: 1750px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-319 {
  left: 1712px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-320 {
  left: 1674px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-321 {
  left: 1636px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-322 {
  left: 1598px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-323 {
  left: 1560px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-324 {
  left: 1522px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-325 {
  left: 1484px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-326 {
  left: 1446px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-327 {
  left: 1408px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-328 {
  left: 1370px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-329 {
  left: 1332px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-330 {
  left: 1294px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-331 {
  left: 1256px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-332 {
  left: 1218px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-333 {
  left: 1180px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-334 {
  left: 1142px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-335 {
  left: 1104px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-336 {
  left: 1066px !important;
  top: 3610px !important;
}

.planting-tree-screen .planting-tree-337 {
  left: 2434px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-338 {
  left: 2396px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-339 {
  left: 2358px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-340 {
  left: 2320px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-341 {
  left: 2282px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-342 {
  left: 2244px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-343 {
  left: 2206px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-344 {
  left: 2168px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-345 {
  left: 2130px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-346 {
  left: 2092px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-347 {
  left: 2054px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-348 {
  left: 2016px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-349 {
  left: 1978px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-350 {
  left: 1940px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-351 {
  left: 1902px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-352 {
  left: 1864px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-353 {
  left: 1826px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-354 {
  left: 1788px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-355 {
  left: 1750px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-356 {
  left: 1712px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-357 {
  left: 1674px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-358 {
  left: 1636px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-359 {
  left: 1598px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-360 {
  left: 1560px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-361 {
  left: 1522px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-362 {
  left: 1484px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-363 {
  left: 1446px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-364 {
  left: 1408px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-365 {
  left: 1370px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-366 {
  left: 1332px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-367 {
  left: 1294px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-368 {
  left: 1256px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-369 {
  left: 1218px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-370 {
  left: 1180px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-371 {
  left: 1142px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-372 {
  left: 1104px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-373 {
  left: 1066px !important;
  top: 3573px !important;
}

.planting-tree-screen .planting-tree-374 {
  left: 2434px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-375 {
  left: 2396px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-376 {
  left: 2358px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-377 {
  left: 2320px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-378 {
  left: 2282px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-379 {
  left: 2244px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-380 {
  left: 2206px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-381 {
  left: 2168px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-382 {
  left: 2130px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-383 {
  left: 2092px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-384 {
  left: 2054px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-385 {
  left: 2016px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-386 {
  left: 1978px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-387 {
  left: 1940px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-388 {
  left: 1902px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-389 {
  left: 1864px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-390 {
  left: 1826px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-391 {
  left: 1788px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-392 {
  left: 1750px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-393 {
  left: 1712px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-394 {
  left: 1674px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-395 {
  left: 1636px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-396 {
  left: 1598px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-397 {
  left: 1560px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-398 {
  left: 1522px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-399 {
  left: 1484px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-400 {
  left: 1446px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-401 {
  left: 1408px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-402 {
  left: 1370px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-403 {
  left: 1332px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-404 {
  left: 1294px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-405 {
  left: 1256px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-406 {
  left: 1218px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-407 {
  left: 1180px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-408 {
  left: 1142px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-409 {
  left: 1104px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-410 {
  left: 1066px !important;
  top: 3536px !important;
}

.planting-tree-screen .planting-tree-411 {
  left: 2434px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-412 {
  left: 2396px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-413 {
  left: 2358px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-414 {
  left: 2320px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-415 {
  left: 2282px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-416 {
  left: 2244px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-417 {
  left: 2206px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-418 {
  left: 2168px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-419 {
  left: 2130px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-420 {
  left: 2092px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-421 {
  left: 2054px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-422 {
  left: 2016px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-423 {
  left: 1978px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-424 {
  left: 1940px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-425 {
  left: 1902px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-426 {
  left: 1864px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-427 {
  left: 1826px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-428 {
  left: 1788px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-429 {
  left: 1750px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-430 {
  left: 1712px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-431 {
  left: 1674px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-432 {
  left: 1636px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-433 {
  left: 1598px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-434 {
  left: 1560px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-435 {
  left: 1522px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-436 {
  left: 1484px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-437 {
  left: 1446px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-438 {
  left: 1408px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-439 {
  left: 1370px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-440 {
  left: 1332px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-441 {
  left: 1294px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-442 {
  left: 1256px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-443 {
  left: 1218px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-444 {
  left: 1180px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-445 {
  left: 1142px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-446 {
  left: 1104px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-447 {
  left: 1066px !important;
  top: 3499px !important;
}

.planting-tree-screen .planting-tree-448 {
  left: 2434px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-449 {
  left: 2396px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-450 {
  left: 2358px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-451 {
  left: 2320px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-452 {
  left: 2282px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-453 {
  left: 2244px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-454 {
  left: 2206px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-455 {
  left: 2168px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-456 {
  left: 2130px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-457 {
  left: 2092px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-458 {
  left: 2054px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-459 {
  left: 2016px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-460 {
  left: 1978px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-461 {
  left: 1940px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-462 {
  left: 1902px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-463 {
  left: 1864px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-464 {
  left: 1826px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-465 {
  left: 1788px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-466 {
  left: 1750px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-467 {
  left: 1712px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-468 {
  left: 1674px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-469 {
  left: 1636px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-470 {
  left: 1598px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-471 {
  left: 1560px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-472 {
  left: 1522px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-473 {
  left: 1484px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-474 {
  left: 1446px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-475 {
  left: 1408px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-476 {
  left: 1370px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-477 {
  left: 1332px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-478 {
  left: 1294px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-479 {
  left: 1256px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-480 {
  left: 1218px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-481 {
  left: 1180px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-482 {
  left: 1142px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-483 {
  left: 1104px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-484 {
  left: 1066px !important;
  top: 3462px !important;
}

.planting-tree-screen .planting-tree-485 {
  left: 2434px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-486 {
  left: 2396px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-487 {
  left: 2358px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-488 {
  left: 2320px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-489 {
  left: 2282px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-490 {
  left: 2244px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-491 {
  left: 2206px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-492 {
  left: 2168px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-493 {
  left: 2130px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-494 {
  left: 2092px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-495 {
  left: 2054px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-496 {
  left: 2016px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-497 {
  left: 1978px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-498 {
  left: 1940px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-499 {
  left: 1902px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-500 {
  left: 1864px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-501 {
  left: 1826px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-502 {
  left: 1788px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-503 {
  left: 1750px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-504 {
  left: 1712px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-505 {
  left: 1674px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-506 {
  left: 1636px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-507 {
  left: 1598px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-508 {
  left: 1560px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-509 {
  left: 1522px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-510 {
  left: 1484px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-511 {
  left: 1446px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-512 {
  left: 1408px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-513 {
  left: 1370px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-514 {
  left: 1332px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-515 {
  left: 1294px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-516 {
  left: 1256px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-517 {
  left: 1218px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-518 {
  left: 1180px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-519 {
  left: 1142px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-520 {
  left: 1104px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-521 {
  left: 1066px !important;
  top: 3425px !important;
}

.planting-tree-screen .planting-tree-522 {
  left: 2434px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-523 {
  left: 2396px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-524 {
  left: 2358px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-525 {
  left: 2320px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-526 {
  left: 2282px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-527 {
  left: 2244px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-528 {
  left: 2206px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-529 {
  left: 2168px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-530 {
  left: 2130px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-531 {
  left: 2092px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-532 {
  left: 2054px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-533 {
  left: 2016px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-534 {
  left: 1978px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-535 {
  left: 1940px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-536 {
  left: 1902px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-537 {
  left: 1864px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-538 {
  left: 1826px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-539 {
  left: 1788px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-540 {
  left: 1750px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-541 {
  left: 1712px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-542 {
  left: 1674px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-543 {
  left: 1636px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-544 {
  left: 1598px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-545 {
  left: 1560px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-546 {
  left: 1522px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-547 {
  left: 1484px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-548 {
  left: 1446px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-549 {
  left: 1408px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-550 {
  left: 1370px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-551 {
  left: 1332px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-552 {
  left: 1294px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-553 {
  left: 1256px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-554 {
  left: 1218px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-555 {
  left: 1180px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-556 {
  left: 1142px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-557 {
  left: 1104px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-558 {
  left: 1066px !important;
  top: 3388px !important;
}

.planting-tree-screen .planting-tree-559 {
  left: 2434px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-560 {
  left: 2396px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-561 {
  left: 2358px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-562 {
  left: 2320px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-563 {
  left: 2282px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-564 {
  left: 2244px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-565 {
  left: 2206px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-566 {
  left: 2168px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-567 {
  left: 2130px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-568 {
  left: 2092px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-569 {
  left: 2054px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-570 {
  left: 2016px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-571 {
  left: 1978px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-572 {
  left: 1940px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-573 {
  left: 1902px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-574 {
  left: 1864px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-575 {
  left: 1826px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-576 {
  left: 1788px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-577 {
  left: 1750px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-578 {
  left: 1712px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-579 {
  left: 1674px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-580 {
  left: 1636px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-581 {
  left: 1598px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-582 {
  left: 1560px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-583 {
  left: 1522px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-584 {
  left: 1484px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-585 {
  left: 1446px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-586 {
  left: 1408px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-587 {
  left: 1370px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-588 {
  left: 1332px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-589 {
  left: 1294px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-590 {
  left: 1256px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-591 {
  left: 1218px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-592 {
  left: 1180px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-593 {
  left: 1142px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-594 {
  left: 1104px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-595 {
  left: 1066px !important;
  top: 3351px !important;
}

.planting-tree-screen .planting-tree-596 {
  left: 2434px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-597 {
  left: 2396px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-598 {
  left: 2358px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-599 {
  left: 2320px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-600 {
  left: 2282px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-601 {
  left: 2244px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-602 {
  left: 2206px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-603 {
  left: 2168px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-604 {
  left: 2130px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-605 {
  left: 2092px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-606 {
  left: 2054px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-607 {
  left: 2016px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-608 {
  left: 1978px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-609 {
  left: 1940px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-610 {
  left: 1902px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-611 {
  left: 1864px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-612 {
  left: 1826px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-613 {
  left: 1788px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-614 {
  left: 1750px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-615 {
  left: 1712px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-616 {
  left: 1674px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-617 {
  left: 1636px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-618 {
  left: 1598px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-619 {
  left: 1560px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-620 {
  left: 1522px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-621 {
  left: 1484px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-622 {
  left: 1446px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-623 {
  left: 1408px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-624 {
  left: 1370px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-625 {
  left: 1332px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-626 {
  left: 1294px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-627 {
  left: 1256px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-628 {
  left: 1218px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-629 {
  left: 1180px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-630 {
  left: 1142px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-631 {
  left: 1104px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-632 {
  left: 1066px !important;
  top: 3314px !important;
}

.planting-tree-screen .planting-tree-633 {
  left: 2434px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-634 {
  left: 2396px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-635 {
  left: 2358px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-636 {
  left: 2320px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-637 {
  left: 2282px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-638 {
  left: 2244px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-639 {
  left: 2206px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-640 {
  left: 2168px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-641 {
  left: 2130px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-642 {
  left: 2092px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-643 {
  left: 2054px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-644 {
  left: 2016px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-645 {
  left: 1978px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-646 {
  left: 1940px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-647 {
  left: 1902px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-648 {
  left: 1864px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-649 {
  left: 1826px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-650 {
  left: 1788px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-651 {
  left: 1750px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-652 {
  left: 1712px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-653 {
  left: 1674px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-654 {
  left: 1636px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-655 {
  left: 1598px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-656 {
  left: 1560px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-657 {
  left: 1522px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-658 {
  left: 1484px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-659 {
  left: 1446px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-660 {
  left: 1408px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-661 {
  left: 1370px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-662 {
  left: 1332px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-663 {
  left: 1294px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-664 {
  left: 1256px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-665 {
  left: 1218px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-666 {
  left: 1180px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-667 {
  left: 1142px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-668 {
  left: 1104px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-669 {
  left: 1066px !important;
  top: 3277px !important;
}

.planting-tree-screen .planting-tree-670 {
  left: 2434px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-671 {
  left: 2396px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-672 {
  left: 2358px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-673 {
  left: 2320px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-674 {
  left: 2282px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-675 {
  left: 2244px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-676 {
  left: 2206px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-677 {
  left: 2168px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-678 {
  left: 2130px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-679 {
  left: 2092px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-680 {
  left: 2054px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-681 {
  left: 2016px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-682 {
  left: 1978px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-683 {
  left: 1940px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-684 {
  left: 1902px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-685 {
  left: 1864px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-686 {
  left: 1826px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-687 {
  left: 1788px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-688 {
  left: 1750px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-689 {
  left: 1712px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-690 {
  left: 1674px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-691 {
  left: 1636px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-692 {
  left: 1598px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-693 {
  left: 1560px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-694 {
  left: 1522px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-695 {
  left: 1484px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-696 {
  left: 1446px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-697 {
  left: 1408px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-698 {
  left: 1370px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-699 {
  left: 1332px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-700 {
  left: 1294px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-701 {
  left: 1256px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-702 {
  left: 1218px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-703 {
  left: 1180px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-704 {
  left: 1142px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-705 {
  left: 1104px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-706 {
  left: 1066px !important;
  top: 3240px !important;
}

.planting-tree-screen .planting-tree-707 {
  left: 2434px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-708 {
  left: 2396px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-709 {
  left: 2358px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-710 {
  left: 2320px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-711 {
  left: 2282px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-712 {
  left: 2244px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-713 {
  left: 2206px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-714 {
  left: 2168px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-715 {
  left: 2130px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-716 {
  left: 2092px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-717 {
  left: 2054px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-718 {
  left: 2016px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-719 {
  left: 1978px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-720 {
  left: 1940px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-721 {
  left: 1902px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-722 {
  left: 1864px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-723 {
  left: 1826px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-724 {
  left: 1788px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-725 {
  left: 1750px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-726 {
  left: 1712px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-727 {
  left: 1674px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-728 {
  left: 1636px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-729 {
  left: 1598px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-730 {
  left: 1560px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-731 {
  left: 1522px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-732 {
  left: 1484px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-733 {
  left: 1446px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-734 {
  left: 1408px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-735 {
  left: 1370px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-736 {
  left: 1332px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-737 {
  left: 1294px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-738 {
  left: 1256px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-739 {
  left: 1218px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-740 {
  left: 1180px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-741 {
  left: 1142px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-742 {
  left: 1104px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-743 {
  left: 1066px !important;
  top: 3203px !important;
}

.planting-tree-screen .planting-tree-744 {
  left: 2434px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-745 {
  left: 2396px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-746 {
  left: 2358px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-747 {
  left: 2320px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-748 {
  left: 2282px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-749 {
  left: 2244px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-750 {
  left: 2206px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-751 {
  left: 2168px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-752 {
  left: 2130px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-753 {
  left: 2092px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-754 {
  left: 2054px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-755 {
  left: 2016px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-756 {
  left: 1978px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-757 {
  left: 1940px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-758 {
  left: 1902px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-759 {
  left: 1864px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-760 {
  left: 1826px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-761 {
  left: 1788px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-762 {
  left: 1750px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-763 {
  left: 1712px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-764 {
  left: 1674px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-765 {
  left: 1636px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-766 {
  left: 1598px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-767 {
  left: 1560px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-768 {
  left: 1522px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-769 {
  left: 1484px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-770 {
  left: 1446px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-771 {
  left: 1408px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-772 {
  left: 1370px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-773 {
  left: 1332px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-774 {
  left: 1294px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-775 {
  left: 1256px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-776 {
  left: 1218px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-777 {
  left: 1180px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-778 {
  left: 1142px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-779 {
  left: 1104px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-780 {
  left: 1066px !important;
  top: 3166px !important;
}

.planting-tree-screen .planting-tree-781 {
  left: 2434px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-782 {
  left: 2396px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-783 {
  left: 2358px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-784 {
  left: 2320px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-785 {
  left: 2282px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-786 {
  left: 2244px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-787 {
  left: 2206px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-788 {
  left: 2168px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-789 {
  left: 2130px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-790 {
  left: 2092px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-791 {
  left: 2054px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-792 {
  left: 2016px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-793 {
  left: 1978px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-794 {
  left: 1940px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-795 {
  left: 1902px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-796 {
  left: 1864px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-797 {
  left: 1826px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-798 {
  left: 1788px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-799 {
  left: 1750px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-800 {
  left: 1712px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-801 {
  left: 1674px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-802 {
  left: 1636px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-803 {
  left: 1598px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-804 {
  left: 1560px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-805 {
  left: 1522px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-806 {
  left: 1484px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-807 {
  left: 1446px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-808 {
  left: 1408px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-809 {
  left: 1370px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-810 {
  left: 1332px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-811 {
  left: 1294px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-812 {
  left: 1256px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-813 {
  left: 1218px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-814 {
  left: 1180px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-815 {
  left: 1142px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-816 {
  left: 1104px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-817 {
  left: 1066px !important;
  top: 3129px !important;
}

.planting-tree-screen .planting-tree-818 {
  left: 2434px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-819 {
  left: 2396px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-820 {
  left: 2358px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-821 {
  left: 2320px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-822 {
  left: 2282px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-823 {
  left: 2244px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-824 {
  left: 2206px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-825 {
  left: 2168px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-826 {
  left: 2130px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-827 {
  left: 2092px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-828 {
  left: 2054px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-829 {
  left: 2016px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-830 {
  left: 1978px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-831 {
  left: 1940px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-832 {
  left: 1902px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-833 {
  left: 1864px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-834 {
  left: 1826px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-835 {
  left: 1788px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-836 {
  left: 1750px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-837 {
  left: 1712px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-838 {
  left: 1674px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-839 {
  left: 1636px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-840 {
  left: 1598px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-841 {
  left: 1560px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-842 {
  left: 1522px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-843 {
  left: 1484px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-844 {
  left: 1446px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-845 {
  left: 1408px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-846 {
  left: 1370px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-847 {
  left: 1332px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-848 {
  left: 1294px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-849 {
  left: 1256px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-850 {
  left: 1218px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-851 {
  left: 1180px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-852 {
  left: 1142px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-853 {
  left: 1104px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-854 {
  left: 1066px !important;
  top: 3092px !important;
}

.planting-tree-screen .planting-tree-855 {
  left: 2434px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-856 {
  left: 2396px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-857 {
  left: 2358px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-858 {
  left: 2320px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-859 {
  left: 2282px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-860 {
  left: 2244px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-861 {
  left: 2206px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-862 {
  left: 2168px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-863 {
  left: 2130px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-864 {
  left: 2092px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-865 {
  left: 2054px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-866 {
  left: 2016px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-867 {
  left: 1978px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-868 {
  left: 1940px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-869 {
  left: 1902px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-870 {
  left: 1864px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-871 {
  left: 1826px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-872 {
  left: 1788px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-873 {
  left: 1750px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-874 {
  left: 1712px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-875 {
  left: 1674px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-876 {
  left: 1636px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-877 {
  left: 1598px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-878 {
  left: 1560px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-879 {
  left: 1522px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-880 {
  left: 1484px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-881 {
  left: 1446px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-882 {
  left: 1408px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-883 {
  left: 1370px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-884 {
  left: 1332px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-885 {
  left: 1294px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-886 {
  left: 1256px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-887 {
  left: 1218px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-888 {
  left: 1180px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-889 {
  left: 1142px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-890 {
  left: 1104px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-891 {
  left: 1066px !important;
  top: 3055px !important;
}

.planting-tree-screen .planting-tree-892 {
  left: 2434px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-893 {
  left: 2396px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-894 {
  left: 2358px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-895 {
  left: 2320px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-896 {
  left: 2282px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-897 {
  left: 2244px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-898 {
  left: 2206px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-899 {
  left: 2168px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-900 {
  left: 2130px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-901 {
  left: 2092px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-902 {
  left: 2054px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-903 {
  left: 2016px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-904 {
  left: 1978px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-905 {
  left: 1940px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-906 {
  left: 1902px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-907 {
  left: 1864px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-908 {
  left: 1826px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-909 {
  left: 1788px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-910 {
  left: 1750px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-911 {
  left: 1712px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-912 {
  left: 1674px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-913 {
  left: 1636px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-914 {
  left: 1598px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-915 {
  left: 1560px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-916 {
  left: 1522px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-917 {
  left: 1484px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-918 {
  left: 1446px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-919 {
  left: 1408px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-920 {
  left: 1370px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-921 {
  left: 1332px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-922 {
  left: 1294px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-923 {
  left: 1256px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-924 {
  left: 1218px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-925 {
  left: 1180px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-926 {
  left: 1142px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-927 {
  left: 1104px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-928 {
  left: 1066px !important;
  top: 3018px !important;
}

.planting-tree-screen .planting-tree-929 {
  left: 2434px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-930 {
  left: 2396px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-931 {
  left: 2358px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-932 {
  left: 2320px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-933 {
  left: 2282px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-934 {
  left: 2244px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-935 {
  left: 2206px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-936 {
  left: 2168px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-937 {
  left: 2130px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-938 {
  left: 2092px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-939 {
  left: 2054px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-940 {
  left: 2016px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-941 {
  left: 1978px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-942 {
  left: 1940px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-943 {
  left: 1902px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-944 {
  left: 1864px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-945 {
  left: 1826px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-946 {
  left: 1788px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-947 {
  left: 1750px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-948 {
  left: 1712px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-949 {
  left: 1674px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-950 {
  left: 1636px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-951 {
  left: 1598px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-952 {
  left: 1560px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-953 {
  left: 1522px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-954 {
  left: 1484px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-955 {
  left: 1446px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-956 {
  left: 1408px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-957 {
  left: 1370px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-958 {
  left: 1332px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-959 {
  left: 1294px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-960 {
  left: 1256px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-961 {
  left: 1218px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-962 {
  left: 1180px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-963 {
  left: 1142px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-964 {
  left: 1104px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-965 {
  left: 1066px !important;
  top: 2981px !important;
}

.planting-tree-screen .planting-tree-966 {
  left: 2434px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-967 {
  left: 2396px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-968 {
  left: 2358px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-969 {
  left: 2320px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-970 {
  left: 2282px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-971 {
  left: 2244px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-972 {
  left: 2206px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-973 {
  left: 2168px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-974 {
  left: 2130px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-975 {
  left: 2092px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-976 {
  left: 2054px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-977 {
  left: 2016px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-978 {
  left: 1978px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-979 {
  left: 1940px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-980 {
  left: 1902px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-981 {
  left: 1864px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-982 {
  left: 1826px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-983 {
  left: 1788px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-984 {
  left: 1750px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-985 {
  left: 1712px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-986 {
  left: 1674px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-987 {
  left: 1636px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-988 {
  left: 1598px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-989 {
  left: 1560px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-990 {
  left: 1522px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-991 {
  left: 1484px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-992 {
  left: 1446px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-993 {
  left: 1408px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-994 {
  left: 1370px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-995 {
  left: 1332px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-996 {
  left: 1294px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-997 {
  left: 1256px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-998 {
  left: 1218px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-999 {
  left: 1180px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-1000 {
  left: 1142px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-1001 {
  left: 1104px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-1002 {
  left: 1066px !important;
  top: 2944px !important;
}

.planting-tree-screen .planting-tree-1003 {
  left: 2434px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1004 {
  left: 2396px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1005 {
  left: 2358px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1006 {
  left: 2320px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1007 {
  left: 2282px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1008 {
  left: 2244px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1009 {
  left: 2206px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1010 {
  left: 2168px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1011 {
  left: 2130px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1012 {
  left: 2092px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1013 {
  left: 2054px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1014 {
  left: 2016px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1015 {
  left: 1978px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1016 {
  left: 1940px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1017 {
  left: 1902px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1018 {
  left: 1864px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1019 {
  left: 1826px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1020 {
  left: 1788px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1021 {
  left: 1750px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1022 {
  left: 1712px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1023 {
  left: 1674px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1024 {
  left: 1636px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1025 {
  left: 1598px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1026 {
  left: 1560px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1027 {
  left: 1522px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1028 {
  left: 1484px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1029 {
  left: 1446px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1030 {
  left: 1408px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1031 {
  left: 1370px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1032 {
  left: 1332px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1033 {
  left: 1294px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1034 {
  left: 1256px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1035 {
  left: 1218px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1036 {
  left: 1180px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1037 {
  left: 1142px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1038 {
  left: 1104px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1039 {
  left: 1066px !important;
  top: 2907px !important;
}

.planting-tree-screen .planting-tree-1040 {
  margin-bottom: -36.00px !important;
  margin-left: -1.00px !important;
}

.planting-tree-screen .planting-tree-1041 {
  left: 2434px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1042 {
  margin-bottom: -36.00px !important;
}

.planting-tree-screen .planting-tree-1043 {
  left: 2396px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1044 {
  left: 2358px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1045 {
  left: 2320px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1046 {
  left: 2282px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1047 {
  left: 2244px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1048 {
  left: 2206px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1049 {
  left: 2168px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1050 {
  left: 2130px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1051 {
  left: 2092px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1052 {
  left: 2054px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1053 {
  left: 2016px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1054 {
  left: 1978px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1055 {
  left: 1940px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1056 {
  left: 1902px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1057 {
  left: 1864px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1058 {
  left: 1826px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1059 {
  left: 1788px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1060 {
  left: 1750px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1061 {
  left: 1712px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1062 {
  left: 1674px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1063 {
  left: 1636px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1064 {
  left: 1598px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1065 {
  left: 1560px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1066 {
  left: 1522px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1067 {
  left: 1484px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1068 {
  left: 1446px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1069 {
  left: 1408px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1070 {
  left: 1370px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1071 {
  left: 1332px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1072 {
  left: 1294px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1073 {
  left: 1256px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1074 {
  left: 1218px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1075 {
  left: 1180px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1076 {
  left: 1142px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1077 {
  left: 1104px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1078 {
  left: 1066px !important;
  top: 2870px !important;
}

.planting-tree-screen .planting-tree-1079 {
  margin-bottom: -73.00px !important;
  margin-left: -1.00px !important;
}

.planting-tree-screen .planting-tree-1080 {
  left: 2434px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1081 {
  margin-bottom: -73.00px !important;
}

.planting-tree-screen .planting-tree-1082 {
  left: 2396px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1083 {
  left: 2358px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1084 {
  left: 2320px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1085 {
  left: 2282px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1086 {
  left: 2244px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1087 {
  left: 2206px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1088 {
  left: 2168px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1089 {
  left: 2130px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1090 {
  left: 2092px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1091 {
  left: 2054px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1092 {
  left: 2016px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1093 {
  left: 1978px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1094 {
  left: 1940px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1095 {
  left: 1902px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1096 {
  left: 1864px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1097 {
  left: 1826px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1098 {
  left: 1788px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1099 {
  left: 1750px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1100 {
  left: 1712px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1101 {
  left: 1674px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1102 {
  left: 1636px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1103 {
  left: 1598px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1104 {
  left: 1560px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1105 {
  left: 1522px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1106 {
  left: 1484px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1107 {
  left: 1446px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1108 {
  left: 1408px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1109 {
  left: 1370px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1110 {
  left: 1332px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1111 {
  left: 1294px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1112 {
  left: 1256px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1113 {
  left: 1218px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1114 {
  left: 1180px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1115 {
  left: 1142px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1116 {
  left: 1104px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1117 {
  left: 1066px !important;
  top: 2833px !important;
}

.planting-tree-screen .planting-tree-1118 {
  margin-bottom: -110.00px !important;
  margin-left: -1.00px !important;
}

.planting-tree-screen .planting-tree-1119 {
  left: 2434px !important;
  top: 2796px !important;
}

.planting-tree-screen .planting-tree-1120 {
  margin-bottom: -110.00px !important;
}

.planting-tree-screen .planting-tree-1121 {
  left: 2396px !important;
  top: 2796px !important;
}

.planting-tree-screen .planting-tree-1122 {
  left: 2358px !important;
  top: 2796px !important;
}

.planting-tree-screen .planting-tree-1123 {
  left: 2320px !important;
  top: 2796px !important;
}

.planting-tree-screen .planting-tree-1124 {
  left: 2282px !important;
  top: 2796px !important;
}

.planting-tree-screen .planting-tree-1125 {
  left: 2244px !important;
  top: 2796px !important;
}

.planting-tree-screen .planting-tree-1126 {
  left: 2206px !important;
  top: 2796px !important;
}

.planting-tree-screen .planting-tree-1127 {
  left: 2168px !important;
  top: 2796px !important;
}

.planting-tree-screen .planting-tree-1128 {
  left: 2130px !important;
  top: 2796px !important;
}

.planting-tree-screen .planting-tree-1129 {
  left: 2092px !important;
  top: 2796px !important;
}

.planting-tree-screen .planting-tree-1130 {
  left: 2054px !important;
  top: 2796px !important;
}

.planting-tree-screen .planting-tree-1131 {
  left: 2016px !important;
  top: 2796px !important;
}

.planting-tree-screen .frame {
  height: 176px;
  left: 485px;
  position: absolute;
  top: 822px;
  width: 471px;
}

.planting-tree-screen .button-wrapper {
  height: 54px;
  left: 0;
  position: absolute;
  top: 84px;
  width: 471px;
}

.planting-tree-screen .button {
  all: unset;
  align-items: center;
  background-color: var(--error-500);
  border-radius: 113px;
  box-sizing: border-box;
  display: flex;
  gap: 16px;
  height: 54px;
  justify-content: center;
  left: 124px;
  overflow: hidden;
  padding: 12px 16px;
  position: relative;
  width: 223px;
}

.planting-tree-screen .text-wrapper {
  color: var(--basewhite);
  font-family: var(--body-medium-font-family);
  font-size: var(--body-medium-font-size);
  font-style: var(--body-medium-font-style);
  font-weight: var(--body-medium-font-weight);
  letter-spacing: var(--body-medium-letter-spacing);
  line-height: var(--body-medium-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.planting-tree-screen .div {
  color: var(--ecogreen-900);
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 181px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 40px;
  white-space: nowrap;
}

.planting-tree-screen .button-2 {
  align-items: center;
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--graymain-20);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  height: 72px;
  justify-content: space-between;
  left: 1360px;
  overflow: hidden;
  padding: 16px 8px;
  position: fixed;
  top: 868px;
  width: 40px;
}

.planting-tree-screen .icons-font-awesome-instance {
  color: var(--ecogreen-1000) !important;
}

.planting-tree-screen .divider {
  align-self: stretch;
  height: 1px;
  margin-left: -0.50px;
  margin-right: -0.50px;
  object-fit: cover;
  position: relative;
  width: 100%;
}
