.btn-ecovest {
  align-items: center;
  border-radius: 104px;
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.btn-ecovest .become-an-eco {
  font-family: "Kufam", Helvetica;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.btn-ecovest.two-default {
  background-color: #9ecf3614;
  border: 1px solid;
  border-color: #9ecf36;
  padding: 24px 40px;
}

.btn-ecovest.one-hover {
  border: 1px solid;
  border-color: var(--basewhite);
  padding: 32px 40px;
}

.btn-ecovest.two-hover {
  background-color: #9ecf36;
  padding: 24px 40px;
}

.btn-ecovest.one-default {
  background-color: var(--basewhite);
  padding: 32px 40px;
}

.btn-ecovest.two-default .become-an-eco {
  color: var(--ecogreen-500);
  font-size: 14px;
}

.btn-ecovest.one-hover .become-an-eco {
  color: var(--basewhite);
  font-size: 18px;
}

.btn-ecovest.two-hover .become-an-eco {
  color: var(--basewhite);
  font-size: 14px;
}

.btn-ecovest.one-default .become-an-eco {
  color: var(--ecogreen-500);
  font-size: 18px;
}
