.notif-breathing {
  background-color: var(--brand-colorsdeep-carmine-pink);
  border-radius: 2px;
  height: 4px;
  width: 4px;
}

.notif-breathing.in {
  box-shadow: 0px 0px 0px 5px #ff373733;
}

.notif-breathing.out {
  box-shadow: 0px 0px 0px #ff373733;
}
