.planting-tree {
  border-radius: 2px;
  position: relative;
}

.planting-tree .image {
  height: 45px;
  position: absolute;
  width: 27px;
}

.planting-tree.hover {
  height: 37px;
  width: 38px;
}

.planting-tree.default {
  border: 1px solid !important;
  border-color: #0000000a !important;
  height: 39px;
  width: 40px;
}

.planting-tree.hover.on {
  background-color: #6de3073b;
  border-color: #52b000;
}

.planting-tree.hover.off {
  background-color: #ff3d3d3b;
  border-color: #e21515;
}

.planting-tree.hover .image {
  left: 6px;
  top: -19px;
}

.planting-tree.default .image {
  top: -311px;
}

.planting-tree.default.off .image {
  left: 350px;
}

.planting-tree.default.on .image {
  left: 413px;
}
