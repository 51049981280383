.progress-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .progress-ring {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  .progress-ring__circle {
    transition: stroke-dasharray 0.35s;
    transform-origin: 50% 50%;
    animation: fill linear 3s;
  }
  
  .profile-image {
    width: 62px;
    height: 60px;
    border-radius: 50%;
    display: block;
  }

.CircularProgressbar .CircularProgressbar-path {
    stroke: #21ac62 !important;
    stroke-linecap: unset !important;
    stroke-width: 9% !important;
    
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: transparent !important;
  stroke-linecap: unset !important;
}