.planting-tree-wlcm {
  align-items: center;
  border-radius: 4.6px;
  display: inline-flex;
  gap: 4px;
  height: 22px;
  position: relative;
}

.planting-tree-wlcm .image {
  height: 22px;
  position: relative;
  width: 14px;
}

.planting-tree-wlcm .text-wrapper {
  color: var(--basewhite);
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}
