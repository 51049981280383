.instance-tooltip {
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.instance-tooltip.tooltip-position-bottom {
  align-items: center;
}

.instance-tooltip.tooltip-position-top {
  align-items: center;
}

.instance-tooltip.tooltip-position-left {
  align-items: flex-end;
  justify-content: center;
}

.instance-tooltip.tooltip-position-right {
  align-items: flex-end;
  justify-content: center;
}
