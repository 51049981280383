.tool-tip {
  align-items: center;
  background-color: var(--graymain-1000);
  border-radius: 4px;
  box-shadow: var(--shadow-sm);
  display: inline-flex;
  gap: 8px;
  height: 32px;
  justify-content: center;
  padding: 8px 16px;
  position: relative;
}

.tool-tip .information-text {
  color: var(--basewhite);
  font-family: var(--body-large-font-family);
  font-size: var(--body-large-font-size);
  font-style: var(--body-large-font-style);
  font-weight: var(--body-large-font-weight);
  letter-spacing: var(--body-large-letter-spacing);
  line-height: var(--body-large-line-height);
  margin-bottom: -3.00px;
  margin-top: -5.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.tool-tip .indicator {
  background-color: var(--graymain-1000);
  height: 8px;
  position: absolute;
  transform: rotate(-45deg);
  width: 8px;
}

.tool-tip .right {
  left: -3px;
  top: 12px;
}

.tool-tip .bottom {
  left: 70px;
  top: -3px;
}

.tool-tip .left {
  left: 143px;
  top: 12px;
}

.tool-tip .top {
  left: 70px;
  top: 28px;
}
