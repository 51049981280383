.earn-XP {
  background-color: #f3f9ec;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.earn-XP .div {
  background-color: var(--ecogreen-50);
  height: 1000px;
  overflow: hidden;
  position: relative;
  width: 1068px;
}

.earn-XP .button {
  align-items: center;
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--ecogreen-200);
  border-radius: 32px;
  display: flex;
  gap: 16px;
  height: 38px;
  justify-content: center;
  left: 1006px;
  overflow: hidden;
  padding: 16px;
  position: absolute;
  top: 24px;
  width: 38px;
}

.earn-XP .icons-font-awesome-instance {
  margin-bottom: -5.00px !important;
  margin-left: -5.00px !important;
  margin-right: -5.00px !important;
  margin-top: -5.00px !important;
}

.earn-XP .design-component-instance-node {
  color: var(--ecogreen-1000) !important;
}

.earn-XP .table-row {
  -webkit-backdrop-filter: blur(14.4px) brightness(100%);
  align-items: center;
  backdrop-filter: blur(14.4px) brightness(100%);
  background-color: var(--ecogreen-100);
  border-radius: 8px;
  display: flex;
  gap: 8px;
  justify-content: center;
  left: 627px;
  padding: 8px;
  position: absolute;
  top: 994px;
  width: 425px;
}

.earn-XP .frame {
  background-image: url(/public/assets/images/ecovestearnxp/frame-34119.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 8px;
  height: 46px;
  position: relative;
  width: 46px;
}

.earn-XP .frame-2 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  position: relative;
}

.earn-XP .user {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.earn-XP .text-wrapper {
  color: var(--ecogreen-1000);
  flex: 1;
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
}

.earn-XP .div-wrapper {
  align-items: center;
  background-color: var(--ecogreen-200);
  border-radius: 22px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 0px 8px;
  position: relative;
}

.earn-XP .text-wrapper-2 {
  color: var(--ecogreen-900);
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.earn-XP .text-wrapper-3 {
  align-self: stretch;
  color: var(--ecogreen-1000);
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.earn-XP .objectives-wrapper {
  align-items: flex-end;
  display: inline-flex;
  gap: 16px;
  left: 24px;
  position: absolute;
  top: 24px;
}

.earn-XP .objectives {
  color: var(--ecogreen-800);
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 32px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.earn-XP .container {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 24px;
  position: absolute;
  top: 153px;
}

.earn-XP .container-2 {
  -webkit-backdrop-filter: blur(14.4px) brightness(100%);
  align-items: center;
  backdrop-filter: blur(14.4px) brightness(100%);
  background-color: #e4f2d5;
  border-radius: 16px;
  display: flex;
  flex: 0 0 auto;
  gap: 48px;
  overflow: hidden;
  padding: 24px;
  position: relative;
  width: 1020px;
}

.earn-XP .text-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 539px;
}

.earn-XP .title {
  align-self: stretch;
  color: var(--ecogreen-1000);
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 22px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
}

.earn-XP .description {
  align-self: stretch;
  color: var(--ecogreen-800);
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.earn-XP .progress {
  color: var(--ecogreen-800);
  flex: 1;
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.earn-XP .XP-container {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  justify-content: flex-end;
  position: relative;
}

.earn-XP .green-coin {
  height: 33px;
  position: relative;
  width: 33px;
}

.earn-XP .XP-text {
  color: var(--ecogreen-500);
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.earn-XP .right-icon {
  align-items: center;
  background-color: var(--graymain-900);
  border-radius: 8px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 16px;
  justify-content: center;
  overflow: hidden;
  padding: 12px 16px;
  position: relative;
}

.earn-XP .button-2 {
  all: unset;
  box-sizing: border-box;
  color: var(--basewhite);
  font-family: var(--body-medium-font-family);
  font-size: var(--body-medium-font-size);
  font-style: var(--body-medium-font-style);
  font-weight: var(--body-medium-font-weight);
  letter-spacing: var(--body-medium-letter-spacing);
  line-height: var(--body-medium-line-height);
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.earn-XP .note {
  -webkit-backdrop-filter: blur(14.4px) brightness(100%);
  align-items: center;
  backdrop-filter: blur(14.4px) brightness(100%);
  background-color: var(--blue-light100);
  border: 1px solid;
  border-color: var(--blue-light200);
  border-radius: 8px;
  display: flex;
  gap: 16px;
  left: 24px;
  overflow: hidden;
  padding: 8px 24px;
  position: absolute;
  top: 85px;
  width: 1020px;
}

.earn-XP .XP-container-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: flex-end;
  position: relative;
}

.earn-XP .img {
  height: 16px;
  position: relative;
  width: 16px;
}

.earn-XP .XP-text-2 {
  color: var(--ecogreen-500);
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.earn-XP .title-2 {
  color: var(--ecogreen-1000);
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 22px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.earn-XP .planting-tree-available-trees {
  flex: 0 0 auto !important;
  height: 32px !important;
}

.earn-XP .planting-tree-instance {
  color: var(--ecogreen-1000) !important;
  font-weight: 900 !important;
}
