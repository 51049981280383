.icons-font-awesome {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.icons-font-awesome .icon {
  align-self: stretch;
  color: var(--brand-colorsdeep-carmine-pink);
  flex: 1;
  font-family: "Font Awesome 6 Pro-Regular", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.icons-font-awesome.twenty-four-px {
  height: 24px;
  width: 24px;
}

.icons-font-awesome.fourteen-px {
  height: 14px;
  width: 14px;
}

.icons-font-awesome.twelve-px {
  height: 12px;
  width: 12px;
}

.icons-font-awesome.sixteen-px {
  height: 16px;
  width: 16px;
}

.icons-font-awesome.thirty-two-px {
  height: 32px;
  width: 32px;
}

.icons-font-awesome.forty-px {
  height: 40px;
  width: 40px;
}

.icons-font-awesome.twenty-px {
  height: 20px;
  width: 20px;
}

.icons-font-awesome.sixty-four-px {
  height: 64px;
  width: 64px;
}

.icons-font-awesome.twenty-four-px .icon {
  font-size: 20px;
  white-space: nowrap;
}

.icons-font-awesome.fourteen-px .icon {
  font-size: 12px;
  white-space: nowrap;
}

.icons-font-awesome.twelve-px .icon {
  font-size: 10px;
  white-space: nowrap;
}

.icons-font-awesome.sixteen-px .icon {
  font-size: 14px;
  white-space: nowrap;
}

.icons-font-awesome.thirty-two-px .icon {
  font-size: 24px;
}

.icons-font-awesome.forty-px .icon {
  font-size: 32px;
}

.icons-font-awesome.twenty-px .icon {
  font-size: 16px;
}

.icons-font-awesome.sixty-four-px .icon {
  font-size: 56px;
  white-space: nowrap;
}
