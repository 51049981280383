* {
  margin: opx;
  padding: 0px;
}
span.hrboth {
  text-align: center;
  position: relative;
  color: #00000054;
  font-weight: 500;
}

span.hrboth:before {
  display: block;
  content: "";
  height: 1px;
  width: 46%;
  background-color: #d5d7dea1;
  position: absolute;
  left: 0;
  top: 44%;
}

span.hrboth:after {
  display: block;
  content: "";
  height: 1px;
  width: 46%;
  background-color: #d5d7dea1;
  position: absolute;
  right: 0;
  top: 44%;
}
body {
  background: white;
}

@font-face {
  font-family: Hanken Grotesk;
  src: url(../public/assets/fonts/HankenGrotesk-Regular.ttf);
}

:root {
  --heading-font: Hanken Grotesk;
  --fc: #1B2235;
  --background: linear-gradient(103deg, #000 -1.43%, #2D3858 91.94%);
  --basewhite: rgba(255, 255, 255, 1);
  --body-large-font-family: "Hanken Grotesk", Helvetica;
  --body-large-font-size: 16px;
  --body-large-font-style: normal;
  --body-large-font-weight: 400;
  --body-large-letter-spacing: 0px;
  --body-large-line-height: 24px;
  --body-medium-font-family: "Hanken Grotesk", Helvetica;
  --body-medium-font-size: 14px;
  --body-medium-font-style: normal;
  --body-medium-font-weight: 400;
  --body-medium-letter-spacing: 0px;
  --body-medium-line-height: 16px;
  --body-small-font-family: "Hanken Grotesk", Helvetica;
  --body-small-font-size: 12px;
  --body-small-font-style: normal;
  --body-small-font-weight: 400;
  --body-small-letter-spacing: 0px;
  --body-small-line-height: 16px;
  --brand-colorsbright-gray: rgba(236, 236, 236, 1);
  --brand-colorsdeep-carmine-pink: rgba(233, 53, 63, 1);
  --brand-colorsdeep-koamaru: rgba(45, 56, 88, 1);
  --brand-colorssunglow: rgba(246, 209, 46, 1);
  --display-large-font-family: "Hanken Grotesk", Helvetica;
  --display-large-font-size: 48px;
  --display-large-font-style: normal;
  --display-large-font-weight: 800;
  --display-large-letter-spacing: 0px;
  --display-large-line-height: 64px;
  --display-medium-font-family: "Hanken Grotesk", Helvetica;
  --display-medium-font-size: 40px;
  --display-medium-font-style: normal;
  --display-medium-font-weight: 700;
  --display-medium-letter-spacing: 0px;
  --display-medium-line-height: 56px;
  --display-small-font-family: "Hanken Grotesk", Helvetica;
  --display-small-font-size: 32px;
  --display-small-font-style: normal;
  --display-small-font-weight: 600;
  --display-small-letter-spacing: 0px;
  --display-small-line-height: 48px;
  --ecogreen-100: rgba(228, 242, 213, 1);
  --ecogreen-1000: rgba(22, 38, 13, 1);
  --ecogreen-200: rgba(203, 230, 176, 1);
  --ecogreen-300: rgba(178, 217, 142, 1);
  --ecogreen-400: rgba(138, 194, 89, 1);
  --ecogreen-50: rgba(243, 249, 236, 1);
  --ecogreen-500: rgba(108, 166, 60, 1);
  --ecogreen-600: rgba(82, 132, 44, 1);
  --ecogreen-700: rgba(64, 102, 37, 1);
  --ecogreen-800: rgba(54, 82, 34, 1);
  --ecogreen-900: rgba(41, 61, 28, 1);
  --error-500: rgba(240, 68, 56, 1);
  --graymain-10: rgba(234, 235, 238, 1);
  --graymain-100: rgba(129, 136, 155, 1);
  --graymain-1000: rgba(23, 28, 44, 1);
  --graymain-1100: rgba(18, 22, 35, 1);
  --graymain-1200: rgba(13, 17, 26, 1);
  --graymain-20: rgba(213, 215, 222, 1);
  --graymain-200: rgba(108, 116, 138, 1);
  --graymain-30: rgba(192, 195, 205, 1);
  --graymain-300: rgba(87, 96, 121, 1);
  --graymain-40: rgba(171, 175, 188, 1);
  --graymain-400: rgba(66, 76, 105, 1);
  --graymain-50: rgba(150, 156, 172, 1);
  --graymain-600: rgba(41, 50, 79, 1);
  --graymain-700: rgba(36, 45, 70, 1);
  --graymain-800: rgba(31, 39, 62, 1);
  --graymain-900: rgba(27, 34, 53, 1);
  --headline-large-font-family: "Hanken Grotesk", Helvetica;
  --headline-large-font-size: 24px;
  --headline-large-font-style: normal;
  --headline-large-font-weight: 800;
  --headline-large-letter-spacing: 0px;
  --headline-large-line-height: 32px;
  --headline-medium-font-family: "Hanken Grotesk", Helvetica;
  --headline-medium-font-size: 20px;
  --headline-medium-font-style: normal;
  --headline-medium-font-weight: 600;
  --headline-medium-letter-spacing: 0px;
  --headline-medium-line-height: 24px;
  --headline-small-font-family: "Hanken Grotesk", Helvetica;
  --headline-small-font-size: 16px;
  --headline-small-font-style: normal;
  --headline-small-font-weight: 700;
  --headline-small-letter-spacing: 0px;
  --headline-small-line-height: 24px;
  --titre-font-family: "Poppins", Helvetica;
  --titre-font-size: 24px;
  --titre-font-style: normal;
  --titre-font-weight: 700;
  --titre-letter-spacing: 0px;
  --titre-line-height: 24px;
  --shadow-sm: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px
  rgba(16, 24, 40, 0.1);
}

h1 {
  font-family: var(--heading-font);
}

h2 {
  font-family: var(--heading-font);
}

h3 {
  font-family: var(--heading-font);
}

h4 {
  font-family: var(--heading-font);
}

p {
  font-family: var(--heading-font);
}

a {
  text-decoration: none;
}

.nav2 img {
  width: 208px;
  height: 48px;
}

.foruth-cont {
  max-width: 1220px;
}

.menu-main {
  margin: auto !important;
}

#navbarcontainer {
  border-bottom: 1px solid #D5D7DE;
  margin: auto;
  background-color: white !important;
}

.menu-main li {
  padding: 20px;
  font-size: 16px;
  font-family: var(--heading-font);
  font-weight: 600;
  color: #1B2235 !important;
}

.btn1 {
  background: var(--background) !important;
  color: white !important;
  padding: 10px 26px 10px 26px;
}

.second-row {
  height: 140px;
  background: linear-gradient(103deg, #000 -1.43%, #2D3858 91.94%);

}

h2.heading-one {
  color: white;
}

.heading-two.col-12 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.third-row p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-box {
  border-radius: 16px;
}

.ib-icon img {
  width: 40px !important;
}

.para-text {
  width: 1062px !important;
  font-size: 32px;
  margin: auto;
}

.fifth-row {
  background: linear-gradient(180deg, #FFF 0%, rgb(242 248 255) 100%) !important;
}

.text-area {
  width: 1220px !important;
  margin: auto !important;
}

.list-icon p {
  display: inline !important;
  font-size: 16px !important;
  font-weight: 400 !important;

}

.global-hh {
  color: #2F69FE;
  font-size: 20px;
  font-weight: 600;
}

.cont-col p {
  font-size: 32px;
  font-weight: 600;
}

.main-f {
  max-width: 1220px !important;
  margin: auto;
}

.main-fc {
  max-width: 1220px !important;
}

.col-lg-4.col-md-6.mb-4.mb-md-0 {
  padding-left: 165px;
}

.main-c {
  background: black;
}

.sixth-cont {

  max-width: 1220px !important;
}

.fifth-row {
  background: linear-gradient(180deg, #FFF 0%, rgba(242, 248, 255, 0.00) 100%);
}

.col-6.img-col img {
  width: 600px;
}

.col-6.cont-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

p.para-cont {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: black;
}

.container-fluid.seventh-cont.my-3 {
  max-width: 1220px;
}

.bw-adjust {
  width: fit-content;
  padding: 12px 49px 12px 49px;
}

.eight-cont {
  max-width: 1220px;
}

.col-6.img-col2.d-flex.align-item.justify-content-center.flex-wrap img {
  width: 592px;
}

.global-hh2 {
  color: #2F69FE;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.row.artical p {
  text-align: center;
  font-size: 40px;
  font-weight: 600;
}

.btn4 {
  color: #1B2235;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  width: fit-content;
  padding: 12px 16px;
  border: 1px solid #1B2235;
  border-radius: 8px;

}

.container-fluid.sixth-cont.my-3 {
  margin-top: 60px !important;
}

.main-c.container-fluid.p-0 {
  background: linear-gradient(102.61deg, #000000 -1.43%, #2D3858 91.94%);

}

.foot-last {
  border-top: 1px solid #3C4B73;
}

.list-icon.py-3 img {
  background: #E9353F;
  height: 32px;
  width: 32px;
  padding: 8px;
  border-radius: 32px;
  margin-right: 10px;
}

.card {
  box-shadow: 0px 4px 15px 0px #00000014;
  border-radius: 10px !important;
  position: relative !important;
}

.card2 {
  box-shadow: 0px 4px 15px 0px #00000014;
  border-radius: 10px !important;
  position: relative !important;
}

.card3 {
  box-shadow: 0px 4px 15px 0px #00000014;
  border-radius: 10px !important;
  position: relative !important;
}

.card4 {
  box-shadow: 0px 4px 15px 0px #00000014;
  border-radius: 10px !important;
  position: relative !important;
}

.card5 {
  box-shadow: 0px 4px 15px 0px #00000014;
  border-radius: 10px !important;
  position: relative !important;
}

.card6 {
  box-shadow: 0px 4px 15px 0px #00000014;
  border-radius: 10px !important;
  position: relative !important;
}

.row.artical {
  margin-top: 60px;
}

.col-6.img-col img {
  width: 600px;
}

.container-fluid.seventh-cont.my-3.d-flex.justify-content-center.flex-wrap {
  padding-top: 100px !important;
}

.container-fluid.eight-cont.my-5.d-flex.justify-content-center.flex-wrap {
  padding: 100px 0px;
}

.nav-item span {
  margin-right: 3px;
}

.btn1 span {
  margin-left: 5px;
}

h3.head-name {
  font-size: 12px;
  text-transform: uppercase;
  color: gray;
  font-weight: 600;
  margin-bottom: 0px;
}

p.value {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0px;
}

a.value {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0px;
  color: #1b2235;
}

.profile-info.d-flex.align-items-center {
  flex-direction: column;
}

button.btn-6 {
  height: 46px;
  width: 46px !important;
  background: transparent;
  border: none;
}

.btn-6 img {
  height: 16px !important;
}

.user-box {
  height: 50px !important;
  width: 50px !important;
  margin: 0px;

}

.user-box span {
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
}

button.btn-5.p-tab {
  padding: 12px 20px;
  font-family: var(--heading-font);
  color: white;
  background: #1B2235;
  border: 0px;
  border-radius: 8px;
}

button.btn-5.p-tab2 {
  padding: 12px 20px;
  font-family: var(--heading-font);
  color: black;
  background: transparent;
  border: 0px;
  border-radius: 8px;
}

a.btn-5.p-tab {
  padding: 12px 20px;
  font-family: var(--heading-font);
  color: white;
  background: #1B2235;
  border: 1px solid #1B2235;
  border-radius: 8px;
}

a.btn-5.p-tab2 {
  padding: 12px 20px;
  font-family: var(--heading-font);
  color: black;
  background: transparent;
  border: 0px;
  border-radius: 8px;
}

a.btn-5.p-tab2:hover {
  border: 1px solid #1B2235;
}

.tab-btns.d-flex.justify-content-center.my-1 {
  column-gap: 8px !important;
}

button.tablinks.tab {
  padding: 12px 12px !important;
  background: transparent;
  border: 1px solid #D5D7DE;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  width: 155px;
  display: flex;
  justify-content: space-between;
}

.tab span {
  height: 16px !important;
  width: 16px !important;
}

.tab.d-flex.flex-direction-column {
  column-gap: 8px !important;
}

.tf-sec.d-flex.flex-direction-column {
  justify-content: space-between;
  padding-bottom: 16px;
  align-items: center;
}
@media (max-width : 992px){ 
  .tf-sec .tab-container {
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .tf-sec .tab-container .tab {
    flex-wrap: nowrap;
  }
}

input.icon-input__text-field {
  width: 325px;
  padding: 10px 18px;
  border: 0px;
}

.icon-input.d-flex.justify-content-center {
  border: 1px solid #D5D7DE;
  border-radius: 8px;
  align-items: center;
}

svg.svg-inline--fa.fa-magnifying-glass {
  padding: 10px;
  margin: 0px !important;
}

.tf2-sec {
  column-gap: 8px !important;
  font-size: 14px !important;
  line-height: 24px;
  margin: 0px !important;
  font-family: var(--heading-font);
  padding-bottom: 16px;
}

.m-text {
  padding: 8px 16px;
  background: #00000010;
  border-radius: 32px;
}

.m-text1 {
  padding: 8px 16px;
}

.m-text-i {
  height: 40px;
}

.result-text p {
  font-size: 14px !important;
  line-height: 24px !important;
  font-family: var(--heading-font);
  color: #697586;
  font-weight: 400;
}

.global-ct {
  font-size: 12px !important;
  line-height: 16px !important;
  padding: 4px 8px !important;
  border-radius: 32px;
  border: 1px solid #969CAC;

  width: fit-content;
}

.p-card-title {
  color: #1B2235 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  font-weight: 600 !important;
}

.amount {
  font-size: 32px;
  font-family: 'Hanken Grotesk';
  font-weight: 600;
  width: 55%;
}

.remain {
  width: 45%;
  color: #576079;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: end;
}

.remain img {
  padding-right: 5px;
}

.card-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.amount-text {
  font-size: 14px;
  font-family: 'Hanken Grotesk';
  font-weight: 600;
  width: 60%;
  color: #576079;
}

.card-inner2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.investor {
  width: 40%;
  color: #576079;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: end;
}

.investor img {
  padding-right: 5px;
}

.progress {
  height: 5px;
  margin-top: 8px;
}

.profit img {
  height: 10px;
  width: 10px;
  margin: 8px;
}

.profit p {
  color: #1B2235;
  margin: 0px !important;
  font-size: 16px;
  font-weight: 600;
}

.profit.d-flex.flex-direction-column {
  width: 50%;
  font-size: 16px;
  color: gray;
  align-items: baseline;
}

.returned img {
  height: 10px;
  width: 10px;
  margin: 8px;
}

.returned.d-flex.flex-direction-column {
  width: 50%;
  justify-content: end;
  font-size: 16px;
  color: gray;
  align-items: baseline;
}

.returned p {
  margin: 0px !important;
  font-size: 16px;
  font-weight: 600;
  color: #1B2235;
}

.card-inner3.my-4.d-flex.flex-direction-column {
  padding: 16px;
  border: 2px dashed #D5D7DE;
  border-radius: 8px;
  background: #EAEBEE40;
}

button.image-btn {
  position: absolute;
  z-index: 4;
  top: 16px;
  left: 16px;
  padding: 8px 16px;
  border: 1px solid #12B76A;
  background: #12B76A;
  color: white;
  font-size: 14px;
  font-family: 'Hanken Grotesk';
  border-radius: 40px;
  margin: 0px;
  display: flex;
}

button.image-btn img {
  padding-right: 10px;
}

button.image-btn-2 {
  position: absolute;
  z-index: 4;
  top: 16px;
  left: 16px;
  padding: 8px 16px;
  border: 1px solid #FDB022;
  background: #FDB022;
  color: white;
  font-size: 14px;
  font-family: 'Hanken Grotesk';
  border-radius: 40px;
  margin: 0px;
  display: flex;
}

button.image-btn-2 img {
  padding-right: 10px;
}

button.image-btn-3 {
  position: absolute;
  z-index: 4;
  top: 16px;
  left: 16px;
  padding: 8px 16px;
  border: 1px solid #000000;
  background: #000000;
  color: white;
  font-size: 14px;
  font-family: 'Hanken Grotesk';
  border-radius: 40px;
  margin: 0px;
  display: flex;
}

button.image-btn-3 img {
  padding-right: 10px;
}

button.image-btn-4 {
  position: absolute;
  z-index: 4;
  top: 60px;
  left: 16px;
  padding: 8px 16px;
  border: 1px solid #2F69FE;
  background: #2F69FE;
  color: white;
  font-size: 14px;
  font-family: 'Hanken Grotesk';
  border-radius: 40px;
  margin: 0px;
  display: flex;
}

button.image-btn-4 img {
  padding-right: 10px;
}

button.bw-learn.btn.btn-show {
  width: 228px;
  border: 2px solid #576079;
  font-size: 14px;
  border-radius: 8px;
  font-family: 'Hanken Grotesk';
  color: #1B2235;
  font-weight: 500;
}




.top-head {
  align-items: center;
  display: flex;
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
  font-family: 'Hanken Grotesk';
  color: #424C69;
}

.tab-btns2.d-flex.my-1 {
  display: flex;
  justify-content: space-between;
  padding: 16px 0px;

}

button.btn-5.tb-2.p-2 {
  padding: 16px !important;
  background: white;
  border: 1px solid #D5D7DE;
  border-radius: 16px;
}

.top-head img {
  padding-right: 9px !important;
  height: 16px;
}

ul.list-group.list-group-horizontal-xl {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: -webkit-fill-available;
}

.col-sm-6.col-md-5.offset-md-2.col-lg-6.offset-lg-0.button-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row .r2 {
  padding: 20px !important;
}

.show-res {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.date-btn {
  font-family: var(--heading-font);
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid #D5D7DE;
  background: #FFF;

  padding: 12px 16px;
}

.result-text.d-flex p {
  padding: 0px !important;
  margin: 0px !important;
}

.p2s {
  justify-content: space-between;
  align-items: center;
}

.global-ct2 {
  font-size: 12px !important;
  line-height: 16px !important;
  padding: 4px 8px !important;
  border-radius: 32px;
  border: 1px solid #969CAC;
  margin-bottom: 8px;
  color: #12B76A;
  width: fit-content;
}
.global-ct4 {
  font-size: 12px !important;
  line-height: 16px !important;
  padding: 4px 8px !important;
  border-radius: 32px;
  border: 1px solid #F79009;
  margin-bottom: 8px;
  color: #F79009;
  width: fit-content;
}

.g-text {
  color: #12B76A !important;
  padding-left: 10px;
}

.profit.d-flex.pf2 {
  gap: 16px;
  color: #6C748A;
}

.card-inner3.invi.my-4 {
  border: 1px solid #12B76A !important;
  padding: 16px;
  border-radius: 8px;
}

.returned.d-flex.rf2 {
  color: #6C748A;
}

button.ib-buttton {
  padding: 16px 12px;
  gap: 16px;
  background: white;
  display: flex;
  align-items: center;
  border: 2px solid #C0C3CD;
  border-radius: 8px;
  font-size: 14px;
  font-family: var(--heading-font);
}

.button-box {
  flex-direction: column;
  gap: 18px;
  border: 2px solid #C0C3CD;
  border-radius: 8px;

}

h5.custom-top-para-2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  font-family: 'Hanken Grotesk';
}

.s-f1 {
  max-width: 50% !important;
  font-size: 16px;
  font-family: 'Hanken Grotesk';
  padding: 8px 12px;
  border: 1px solid #E3E8EF;
  border-radius: 8px;
  color: #9AA4B2;
  align-items: center;
  gap: 16px;
}

.s-f1 input[type="text"] {
  border: none;
}

.col-lg-6.col-sm-12.d-flex.flex-direction-column {
  flex-direction: column;
}

.container.custom-section-ind {
  padding: 0px !important;
}

.properties-list-2 img {
  border: 1px solid #D5D7DE;
  border-radius: 5px;
  padding: 10px;
}

.properties-list-2:hover {
  background-color: #F2F8FF !important;
}

.row.p-3.properties-list-2.rounded-3.mb-3 {
  cursor: pointer;
}

.mid-tabs.d-flex.flex-direction-column {
  gap: 32px !important;
  justify-content: center;
  font-family: 'Hanken Grotesk';
  font-size: 16px;
  font-weight: normal;
  color: #6C748A !important;
}

.mid-tabs.d-flex.flex-direction-column a {
  color: #6C748A;
  text-decoration: none !important;
}

.mid-tabs.d-flex.flex-direction-column a.active {
  color: #2F69FE !important;
  border-bottom: 2px solid #2F69FE;
  font-weight: 700;
}

.card-group {
  gap: 24px;
  padding-top: 25px !important;
}

.card {
  border-radius: 16px !important;
}

.banner-hd-2 {
  text-align: center;
  font-family: Hanken Grotesk;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
}

h2.custom-main-hd-2 {
  font-weight: 700;
}


.top-tabs.d-flex.flex-direction-column {
  width: 70% !important;
}


.top-tabs.d-flex.flex-direction-column {
  gap: 32px !important;
  font-family: 'Hanken Grotesk';
  font-size: 16px;
  font-weight: normal;
  color: #6C748A !important;
  align-items: center;
}

.top-tabs.d-flex.flex-direction-column a {
  color: #6C748A;
  text-decoration: none !important;
}

.top-tabs.d-flex.flex-direction-column a.active {
  color: #2F69FE !important;
  border-bottom: 2px solid #2F69FE;
  font-weight: 700;
}

.s-f2 {
  max-width: 30% !important;
  font-size: 16px;
  font-family: 'Hanken Grotesk';
  padding: 8px 12px;
  border: 1px solid #E3E8EF;
  border-radius: 8px;
  color: #9AA4B2;
  align-items: center;
  gap: 8px;
}

.s-f2 input[type="text"] {
  border: none;
}

.spacer {
  padding-top: 25px;
}

.first-head h6 {
  display: flex;
  gap: 8px;
  font-size: 16px;
  align-items: center;
  font-family: 'Hanken Grotesk';
  font-weight: 700;
}

h1.head-comp {
  font-size: 40px !important;
  font-weight: 700;
  color: #1B2235;
  line-height: 50px;
}

.card-group {
  max-width: 1200px !important;
  gap: 16px;
}

.row.com-img {
  display: flex;
  justify-content: end;
}

img.card-img-top {
  height: 132px !important;
}
button.view-btn {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  background: #1B2235;
  color: white;
  border: 1px solid #1B2235;
}
a.view-btn {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  background: #1B2235;
  color: white;
  border: 1px solid #1B2235;
}
.view-btn.disabled{
  opacity: 0.6;
  cursor: not-allowed;
}

.comp-body {
  padding: 10px 0px;
}

.card.comp {
  box-shadow: none;
  background: transparent !important;
  border: none;

}

.f-sec th {
  font-family: 'Hanken Grotesk';
  font-size: 16px;
  font-weight: 400;
  color: #1B2235;
}

td span {
  font-size: 24px;
  font-family: 'Hanken Grotesk';
  font-weight: 700;
}

td {

  text-align: center;
}

th {
  text-align: center;
}

th.rows {
  text-align: start;
}

thead.f-sec {
  border: none !important;
}

.tr {
  border: none !important;
  border-style: none !important;
}

th,
td {
  border: unset !important;
}

.col.img-col.bt-box {
  height: 204px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  background-image: url("../public/assets/images/image12.png");
  border: 1px solid #C0C3CD;
  border-radius: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

button.img-btn {
  background: transparent;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  border: 1px solid #C0C3CD;
  border-radius: 8px;
}

.left-card1.d-flex {
  flex-direction: column;
  border-radius: 16px;
  background: var(--White, #FFF);
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.08);
  padding: 24px 13px;
  margin: 0px !important;
}

.left-card1 h3 {
  font-size: 20px;
  font-family: 'Hanken Grotesk';
  color: #1B2235;
  font-weight: 600;
  line-height: 30px;
  margin: 0px !important;
}

.left-card1 h1 {
  font-size: 32px;
  font-weight: 600;
  font-family: 'Hanken Grotesk';
  line-height: 48px;
  margin: 0px !important;
  color: #1B2235;
}

.left-card1 p {
  font-size: 14px;
  font-family: 'Hanken Grotesk';
  line-height: 20px;
  margin: 0px !important;
}

.card-inner3.my-3.d-flex.flex-direction-column {
  padding: 16px;
  border: 2px dotted #EAEBEE;
  border-radius: 16px;
}

.cards-div {

  flex-direction: column;
  gap: 20px;
}


.pd-tabs.d-flex.flex-direction-column {
  gap: 32px !important;
  justify-content: start;
  font-family: 'Hanken Grotesk';
  font-size: 16px;
  font-weight: normal;
  color: #6C748A !important;
  padding: 30px 0px;
}

.pd-tabs.d-flex.flex-direction-column a {
  color: #6C748A;
  text-decoration: none !important;
}

.pd-tabs.d-flex.flex-direction-column a.active {
  color: #2F69FE !important;
  border-bottom: 2px solid #2F69FE;
  font-weight: 700;
}

.img-width img {
  width: -webkit-fill-available;
}

.tab-inner h1 {
  font-size: 32px;
  font-weight: 600;
  color: #1B2235;
}

.tab-inner p {
  font-size: 16px;
}

.tab-inner p.card-text {
  font-size: 14px;
}

h4.blue-text {
  margin: 0px;
  color: #2F69FE;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
}

.col-4.p-detail p {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #242D46;
  font-weight: 400;
  margin: 0px;
  line-height: 30px;
}

.col-4.p-detail p span {
  font-weight: 600;
}
.col.p-detail p {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #242D46;
  font-weight: 400;
  margin: 0px;
  line-height: 30px;
}

.col.p-detail p span {
  font-weight: 600;
}

.tab-inner {
  padding: 24px 10px;
}

.tab-inner h5 {
  font-size: 20px;
  font-weight: 600;
  color: #1B2235;
}

.tab-inner2 h5 {
  font-size: 20px;
  font-weight: 600;
  color: #1B2235;
}

.col.p-detail {
  padding-bottom: 10px;
}

.col.ptab-detail p {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #242D46;
  font-weight: 400;
  margin: 0px;
  line-height: 30px;
}

.col.ptab-detail p span {
  font-weight: 600;
}

.color-tabs {
  padding: 24px 0px !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  width: 112px;
  font-family: 'Hanken Grotesk';
  font-size: 16px;
  color: white;
  background: unset;
  height: 48px;
  margin-top: -4px;
  border-radius: 8px;
}

.item1 button {
  background: #12B76A !important;
  width: 100px;
  color: white !important;
}

.item2 button {
  background: #C6D35E !important;
  width: 100px;
  color: white !important;
}

.item3 button {
  background: #FDF05B !important;
  width: 100px;
  color: white !important;
}

.item4 button {
  background: #E4B84E !important;
  width: 100px;
  color: white !important;
}

.item5 button {
  background: #C8743C !important;
  width: 100px;
  color: white !important;
}

.item6 button {
  background: #BA3633 !important;
  width: 100px;
  color: white !important;
}

.item7 button {
  background: #CA403D !important;
  width: 100px;
  color: white !important;
}

ul#pills-tab {
  padding: 20px 10px !important;
}

.color-tab-inner {
  padding: 24px;
  border: 1px dashed #12B76A;
  border-radius: 16px;
}

h3.cti-heading {
  display: flex;
  gap: 8px;
  color: #12B76A;
  font-weight: 600;
  font-size: 20px;
  font-family: 'Hanken Grotesk';
  align-items: center;
}

.color-tab-inner p {
  margin: 0px;
}

.tab-inner3 {
  border: 1px solid #D5D7DE;
  border-radius: 16px;
  padding: 24px;
}

.tab-inner4 {
  padding: 40px 0px;
}

.tab-inner4 h3 {
  font-size: 32px;
  font-weight: 600;
  color: #1B2235;
  margin: 0px;
}

.col-lg-12.performance-detail {
  padding: 24px;
  background: #F2F8FF;
  border-radius: 18px;
  display: flex;
  gap: 24px;
  flex-direction: column;
}

h4.icon-head {
  display: flex;
  align-items: center;
  color: #2F69FE;
  font-size: 20px;
  font-weight: 600;
}

.sus-box-detail h5 {
  font-size: 16px;
  font-family: 'Hanken Grotesk';
  font-weight: bolder;
}

.sus-box-detail p {
  font-size: 16px;
  font-family: 'Hanken Grotesk';
}

.row.p-dr h3 {
  padding-bottom: 30px;
}

h3.ec-head {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 15px !important;
}

p.trick {
  display: flex;
  gap: 8px;
  font-size: 16px;
  font-family: 'Hanken Grotesk';
  font-weight: 400;
  align-items: center;
}

p.trick span {
  font-weight: 600;
  padding-left: 100px;
}

p.trick2 {
  display: flex;
  gap: 8px;
  font-size: 16px;
  font-family: 'Hanken Grotesk';
  font-weight: 400;
  align-items: center;
}

p.trick2 span {
  font-weight: 600;
  padding-left: 55px;
}

.col.performance-detail {
  padding: 24px 10px;
}

.accordion-button {
  background-color: #F2F8FF;
  border: none;
  border-radius: 16px !important;
}

.accordion {
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.accordion-item {
  border: none !important;
}

.accordian-cont {
  padding-bottom: 40px;
}

button.accordion-button.collapsed {
  gap: 8px;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 2.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "Details";
  font-size: 14px;
  font-family: var(--heading-font);
  font-weight: 400;
  color: #2F69FE;
  background-image: none !important;
  background-repeat: no-repeat;

  transition: transform .2s ease-in-out;
}

.accordion-button:not(.collapsed)::after {
  background-image: none !important;
  transform: unset !important;
}

h4.sm-btn {
  font-size: 16px;
  color: #2F69FE;
  font-family: 'Hanken Grotesk';
  font-weight: 700;
}

h3.map-head {
  padding-bottom: 12px !important;
}

.col.p-detail2 p {
  display: flex;
  gap: 8px;
  font-size: 16px;
  color: #242D46;
  font-weight: 400;
  margin: 0px;
  line-height: 30px;
}

.col.p-detail2 p span {
  font-weight: 600;

  width: 169px;
  display: flex;
  justify-content: end;
}

.tab-inner2 h1 {
  font-size: 32px;
  font-weight: 600;
  color: #1B2235;
  padding: 40px 0px 20px 10px;
}
.row.row-cols-1.row-cols-md-3.g-4.mb-5 {
  padding: 0px !important;
}
.tab-inner-calculator h5 {
  font-size: 20px;
  font-weight: 600;
  font-family: 'Hanken Grotesk';
  color: #1B2235;
}


h6.heading-box {
  font-size: 16px;
  color: #6C748A;
  font-family: 'Hanken Grotesk';
  font-weight: 700;
  padding: 24px;
  margin: 0px;
  border-bottom: 1px solid #EAEBEE;
}

p.amount-box {
  padding: 24px;
  margin: 0px;
  font-size: 20px;
  font-weight: 600;
  color: #1B2235;
}

.tab-inner-calculator .row {
  border: 1px solid #EAEBEE;
  margin: 0px;
  padding: 0px;
  border-radius: 16px;
}

.col-lg-4.calc-tab.text-center {
  padding: 0px;
  border-right: 1px solid #EAEBEE;
}

.col-lg-4.calc-tab2.text-center {
  padding: 0px;
  border-right: 1px solid #EAEBEE;
}

.col-lg-4.calc-tab3.text-center {
  padding: 0px;

}

.col-lg-12.amont-line label.form-label {
  margin: 0px;
  padding: 24px 0px;
  font-size: 16px;
  font-weight: 700;
  color: #242D46;
}

.tab-inner-calculator {
  padding: 24px;
  border: 1px solid #EAEBEE;
  border-radius: 16px;

}

.container.price-list-cont {
  padding: 24px;
  margin-top: 16px;
  border: 1px solid #EAEBEE;
  border-radius: 16px;
}

h2.h-pt {
  font-size: 20px;
  font-weight: 600;
  color: #1B2235;
  padding-bottom: 40px;
}

.col-lg-6.price-list h3 {
  font-size: 16px;
  line-height: 26px;
  padding-bottom: 24px;
  color: #242D46;
  font-weight: 400;
}

.col-lg-6.price-amount h3 {
  font-size: 16px;
  line-height: 26px;
  padding-bottom: 24px;
  color: #1B2235;
  font-weight: 700;
  text-align: end;
}

.col-lg-12.d-flex.main-cont-list {
  border-bottom: 1px solid #EAEBEE;
}

.col-lg-6.price-list-lh h3 {
  font-size: 16px;
  line-height: 26px;
  padding-top: 24px;
  color: #242D46;
  font-weight: 400;
}

.col-lg-6.price-amount-lh h3 {
  font-size: 20px;
  line-height: 30px;
  padding-top: 24px;
  color: #1B2235;
  font-weight: 700;
  text-align: end;
}

.graph-sec.d-flex {
  flex-direction: column;
  width: -webkit-fill-available;
}

.container-lg-12.g-cont.d-flex {
  margin-top: 16px;
  border: 1px solid #EAEBEE;
  border-radius: 16px;
  padding: 24px;
}

.graph-sec.d-flex h3 {
  font-size: 20px;
  padding-bottom: 20px;
}

.graph-sec.d-flex h1 {
  font-size: 32px;
  color: #1B2235;
  font-weight: 600;
  margin: 0px;
}

.graph-sec.d-flex p {
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  color: #1B2235;
  padding-bottom: 16px;
  border-bottom: 1px solid #EAEBEE;
}

.container-fluid {
  max-width: 1220px !important;
}

.container.fullw-li {
  max-width: 100%;
  margin: 16px 0px;
}

section.below-main {
  width: 100%;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.08);
}

.first-head.mb-3 {
  padding-top: 25px;
}

li.list-group-item {
  border: 0px;

}

li.list-group-item {
  display: contents;

}

li.list-group-item span {
  font-weight: 700;
}

.user-box.d-flex.m-0 img {
  /*border: 5px solid #12B76A;*/
  border-radius: 50%;
}

/*.user-box .wrapper {
  width: 50px;
  height: 50px;
  background-image: url('https://i.ibb.co/5T3p5sY/icon-3151974-1280.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
}

.user-box .wrapper .loader {
  border: 5px solid #21ac62;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: fill linear 3s;
}*/

@keyframes fill {
  0% {
    clip-path: polygon(50% 0%, 50% 50%, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }
  12.5% {
    clip-path: polygon(50% 0%, 50% 50%, 100% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }
  37.5% {
    clip-path: polygon(50% 0%, 50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 0%);
  }
  62.5% {
    clip-path: polygon(50% 0%, 50% 50%, 0% 100%, 0% 100%, 0% 100%, 100% 100%, 100% 0%);
  }
  87.5% {
    clip-path: polygon(50% 0%, 50% 50%, 0% 0%, 0% 0%, 0% 100%, 100% 100%, 100% 0%);
  }
  100% {
    clip-path: polygon(50% 0%, 50% 50%, 50% 0%, 0% 0%, 0% 100%, 100% 100%, 100% 0%);
  }
}

.user-box button#dropdownMenuButton1 {
  padding: 0px;
  background: transparent;
  border: none;
  color: #000;
}

.user-box .dropdown-toggle::after {
  display: none !important;
}

.user-box .dropdown {
  display: flex;
  align-items: center;
}

.btn-check:active+.btn-secondary:focus,
.btn-check:checked+.btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: none !important;
  outline: none !important;
}

.user-box button#dropdownMenuButton1:focus {
  outline: none !important;
  box-shadow: unset !important;
}

.dropdown-menu[data-bs-popper] {
  left: -125px !important;
}

.col-lg-7.form-sec {
  padding: 28px 80px 0px 150px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-sec h4 {
  font-size: 16px;
  font-weight: 700;
  color: #424C69;
  margin: 0px;
}

.form-sec h2 {
  font-size: 32px;
  line-height: 42px;
  color: #1B2235;
  font-weight: 600;
  margin: 0px;
  padding-bottom: 8px;
}

.form-sec button {
  width: 100%;
  padding: 12px;
  font-size: 14px;
  color: #1B2235;
  background: transparent;
  font-family: 'Hanken Grotesk';
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #D5D7DE;
}

.hr-text {
  border: 0;
  line-height: 1em;
  position: relative;
  text-align: center;
  height: 1.5em !important;
  font-size: 14px;
  font-family: var(--heading-font);
  font-weight: 600;
  margin: 30px 15px;
}

.hr-text::before {
  content: "";
  background: #D5D7DE;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 2px;
}

.hr-text::after {
  content: attr(data-content);
  position: relative;
  padding: 0 7px;
  line-height: 1.5em;
  color: #81889B;
  background-color: transparent;
}

hr {
  background-color: transparent !important;
}

hr.hr-text.gradient {
  margin: 0px !important;
}

.m-f input#fname {
  width: 100%;
  padding: 8px 30px;
  border: 1px solid #E3E8EF;
  border-radius: 8px;
  color: #9AA4B2;
  font-size: 16px;
  font-family: 'Hanken Grotesk';
  font-weight: 400;
  margin: 8px 0px 24px 0px;
}

.m-f label {
  font-size: 16px;
  color: #1B2235;
  font-family: 'Hanken Grotesk';
  font-weight: 700;
}

.m-f input[type="submit"] {
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  border: none;
  background: #1B2235;
  color: white;
  font-family: 'Hanken Grotesk';
  font-size: 14px;
  margin-bottom: 24px;
}

p.form-bottom {
  font-size: 16px;
  color: #4B5565;
  font-weight: 400;
}

p.form-bottom span {
  text-decoration: underline;
}

p.form-bottom2 {
  font-size: 16px;
  color: #4B5565;
  font-weight: 400;
}

p.form-bottom2 span {
  color: #E9353F;
}

.m-f input#fname:before {
  content: url(../public/assets/icons/before\ Icon.svg );
  background: transparent;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 2px;
}

.img-form {
  position: relative;
}

.m-f span img {
  position: absolute;
  top: 18px;
  left: 6px;
}



.img-form2 {
  position: relative;
}

.m-f span img {
  position: absolute;
  top: 18px;
  left: 6px;
}







.m-f input#email {
  width: 100%;
  padding: 8px 30px;
  border: 1px solid #E3E8EF;
  border-radius: 8px;
  color: #000;
  font-size: 16px;
  font-family: 'Hanken Grotesk';
  font-weight: 400;
  margin: 8px 0px 24px 0px;
}

.m-f input#password {
  width: 100%;
  padding: 8px 30px;
  border: 1px solid #E3E8EF;
  border-radius: 8px;
  color: #000;
  font-size: 16px;
  font-family: 'Hanken Grotesk';
  font-weight: 400;
  margin: 8px 0px 24px 0px;
}

p.v-formtext {
  font-size: 16px;
  font-weight: 400;
  color: #4B5565;
  margin: 0px;
}

.m-vf input.form-control {
  display: flex;
  width: 60px;
  height: 60px;
  padding: 0px !important;
  justify-content: center !important;
  align-items: center !important;
  border: 1px solid #C0C3CD !important;
  border-radius: 16px !important;
}

.m-vf .d-flex.mb-3 {
  justify-content: center;
  gap: 16px;
}

.v-form-last-count-sec h3 {
  margin: 0px;
  font-size: 16px !important;
  display: inline-flex;
  color: #4B5565;
  font-weight: 400;
}

.v-form-last-count-sec h3 span {
  font-weight: 600;
  color: #4B5565;
}




.red1 {
  font-weight: 500 !important;
  color: #E9353F !important;
}

button.w-100.btn.vf-btn {
  font-size: 14px;
  margin-top: 20px;
  color: white;
  background: #1B2235;
  border-radius: 8px;
  width: 100%;
  padding: 16px;
}

.v-form-last-count-sec {
  display: flex;
  justify-content: space-between;
}

section.main-grid2.d-flex {
  max-width: 620px;
  margin: auto;
}

.middle-sec img {

  display: flex;
  margin: auto;
  padding-top: 53px;
}

.middle-sec h2 {
  font-size: 24px;
  color: #1B2235;
  font-weight: 700;
  font-family: 'Hanken Grotesk';
  line-height: 34px;
  padding: 16px 0px;
}


.middle-sec p {
  font-size: 14px;
  font-weight: 500;
  color: #1B2235;
  padding: 0px;
}

.middle-sec {
  background-image: url(../public/assets/images/Congratulations-Gifs-.png);
  z-index: 4;
  background-position: top center;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: -20px;
  background-repeat: no-repeat;
}

.claim-last-sec img {
  padding: 10px 0px !important;
}

.claim-last-sec h2 {
  font-size: 24px;
  font-weight: 700;
  padding: 0px 0px 20px 0px;
  margin: 0px;
}

button.btn.grn-btn {
  width: 620px;
  background: #12B76A;
  color: white;
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
}



.middle-sec2 img {

  display: flex;
  margin: auto;
  padding-top: 53px;
}

.middle-sec2 h2 {
  font-size: 24px;
  color: #1B2235;
  font-weight: 700;
  font-family: 'Hanken Grotesk';
  line-height: 34px;
  padding: 16px 0px;
}


.middle-sec2 p {
  font-size: 14px;
  font-weight: 500;
  color: #1B2235;
  padding: 0px;
}

.middle-sec2 {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

}

button.btn.grn-btn2 {
  width: 620px;
  background: #1B2235;
  color: white;
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
}

.custom-checkbox .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: transparent;
  border: 2px solid #EAEBEE;
  border-radius: 24px;
}

/* On mouse-over, add a grey background color */
.custom-checkbox .container:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox .container input:checked~.checkmark {
  background-image: url(../public/assets/icons/grn\ Button.svg);
  border: none !important;
}


.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}


.custom-checkbox .container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-checkbox label.container {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px !important;
  font-family: 'Hanken Grotesk';
  color: #1B2235;
  font-weight: 700;
  padding: 24px 16px 24px 45px !important;
  border: 2px solid #EAEBEE;
  border-radius: 8px;
  margin: 20px 0px !important;
  line-height: 26px;
  height: 72px !important;
}

.custom-checkbox label.container p {
  font-size: 12px;
  font-weight: 400;
  color: #6C748A;
}



.custom-checkbox .container input:checked~.checkmark {
  top: 23px;
  left: 10px;
}

.custom-checkbox .checkmark {
  top: 23px;
  left: 10px;
}

.claim-last-sec2 {
  padding: 0px;
  margin: 0px !important;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 45px;
}

button.btn.grn-btn3 {
  width: 620px;
  background: #1B2235;
  color: white;
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

button.btn.grn-btn4 {
  width: 620px;
  background: transparent;
  color: #1B2235;
  border-radius: 8px;
  padding: 16px;
  font-size: 16px !important;
  font-weight: 700;
}

.custom-checkbox {
  padding: 0px !important;
}

.container.payment-first-sec {
  padding: 16px;
  border: 1px solid #D5D7DE;
  border-radius: 16px;
  margin-top: 26px;
  margin-bottom: 16px;
}


.left-card2.d-flex {
  flex-direction: column;

  background: var(--White, #FFF);


  margin: 0px !important;
}

.left-card2 h3 {
  font-size: 20px;
  font-family: 'Hanken Grotesk';
  color: #1B2235;
  font-weight: 600;
  line-height: 30px;
  margin: 0px !important;
}

.left-card2 h1 {
  font-size: 32px;
  font-weight: 600;
  font-family: 'Hanken Grotesk';
  line-height: 48px;
  margin: 0px !important;
  color: #1B2235;
}

.left-card2 p {
  font-size: 14px;
  font-family: 'Hanken Grotesk';
  line-height: 20px;
  margin: 0px !important;
}

h2.pay-card-head {
  font-size: 20px;
  font-weight: 500;
  color: #1B2235;
  margin: 0px;
}

.progress.main2 {
  margin: 8px 0px;
}



.card-inner3.mt-3.d-flex.flex-direction-column {
  padding: 16px;
  border: 2px dotted #EAEBEE;
  border-radius: 16px;
}

.cpay-cal {
  margin: auto;
  padding: 24px;
  border: 1px solid #EAEBEE;
  border-radius: 16px;
  margin-bottom: 42px;
}


.cpay-cal h5 {
  color: #1B2235;
  margin: 0px;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 16px;
}
.botm-cal {
  padding: 32px 0px;
}

.botm-cal h3 {
  font-size: 22px;
  font-size: 20px;
  font-weight: 600;
  color: #1B2235;
  padding-bottom: 8px;
}

.botm-cal p {
  font-size: 16px;
  font-weight: 400;
  color: #1B2235;
  display: flex;
  gap: 8px;
  margin: 0px;
}

.botm-cal p span img {
  width: 24px;
  height: 24px;
  
}
.botm-cal2 h3 {
  font-size: 22px;
  font-size: 20px;
  font-weight: 600;
  color: #1B2235;
  padding-bottom: 8px;
}
.botm-cal2 input.form-control {
  font-size: 16px;
  font-family: 'Hanken Grotesk';
  color: #9AA4B2;
  border-left: 0px !important;
  border-right: 0px !important;
  border: 1px solid #E3E8EF;
  padding: 16px 0px;

}

.botm-cal2 span.input-group-text {
  background: transparent;
  border-right: 0px !important;
  border: 1px solid #E3E8EF;
  border-radius: 8px;
}
span.input-group-text.right {
  border-right: 1px solid #E3E8EF !important;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 600;
  color: #ABAFBC;
  font-family: 'Hanken Grotesk';
}
label.form-label.note {
  margin: 0px;
  padding: 16px;
  border: 1px solid #E3E8EF;
  width: -webkit-fill-available;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Hanken Grotesk';
  color: #1B2235;
  margin-bottom: 32px;
}
button.btn.grn-btn5 {
  width: 100%;
  background: #1B2235;
  color: white;
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}




section.wallet.tabs {
  max-width: 1220px !important;
  margin: auto;
  padding: 0px !important;
}

.col-3.left-main {
  margin: 28px 0px;
  border-right: 1px solid #EAEBEE;
  padding: 0px 0px 0px 24px;
}

.col-12.middle-main {
  margin: 28px 0px;
  padding: 0px 0px 0px 24px;
}

.col-2.second-left-main{
  margin: 28px 24px;
  padding: 16px;
  background: #EAEBEE40;
  border: 1px dotted #D5D7DE;
  border-radius: 8px;
}

h3.bal-head {
  font-size: 16px;
  font-weight: 400;
  color: #1B2235;
  margin: 0px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
}

h3.bal-head span img {
  height: 24px;
  width: 24px;
  padding-right: 8px;
}

h3.bal-head2 {
  font-size: 40px;
  font-weight: 700;
  color: #1B2235;
  margin: 0px;
  padding-bottom: 8px;
}

h3.bal-head2 span img {
  width: 56px;
  height: 56px;
  margin-right: 16px;
}
.left-main p {
  text-align: center;
  color: #576079;
  font-size: 14px;
  margin: 0px;
}
.second-left-main p {
  text-align: start;
  color: #576079;
  font-size: 14px;
  margin: 0px;
}
.col.right-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: -webkit-fill-available !important;
  padding: 24px;
}

.col.right-main button {
  border-radius: 8px;
  padding: 12px;
  font-size: 14px;
  margin: 0px;
  color: white;
  background: #1B2235;
  width: -webkit-fill-available;
  border: none;
}
.container.wal-cont {
  margin-top: 43px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  border: 1px solid #EAECF0;
  border-radius: 16px;
  margin-bottom: 24px;
}
section.wallet-tabs2 {
  max-width: 1220px !important;
  margin: auto;
  margin-bottom: 24px;
}
.col.sec-left-main {
  padding: 32px;
  border: 1px solid #EAECF0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 12px;
  box-shadow:  0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}
h3.slm-head {
  color: #1B2235;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 0px !important;
}

h3.slm-head2 {
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  margin: 0px !important;
}

.sec-left-main p {
  font-size: 14px;
  color: #1B2235;
  line-height: 24px;
  margin: 0px !important;
}
.col-7.sec-right-main {
  padding: 32px;
  border: 1px solid #EAECF0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 12px;
  box-shadow:  0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}


.sec-left-main p {
  font-size: 14px;
  color: #1B2235;
  line-height: 24px;
  margin: 0px !important;
}
.wal-cont2 .row {
  gap: 24px !important;
}
section.wallet-tabs3{
  max-width: 1220px !important;
  margin: auto;
  margin-bottom: 54px;
}
.wal-table-form h3.slm-head {
  margin-bottom: 16px !important;
}
.form-group.col-md-3 {
  margin-right: 8px;
}

.wallet-tabs3 .form-row.d-flex.flex-direction-coloum input {
  
  border-radius: 8px !important;
  font-size: 14px;
  font-family: 'Hanken Grotesk';
  color: #1B2235 !important;
  font-weight: 400;
}
.wallet-tabs3 .form-row.d-flex.flex-direction-coloum select#inputState
{
  border: 1px solid #D5D7DE !important;
  border-radius: 8px !important;
  font-size: 14px;
  font-family: 'Hanken Grotesk';
  color: #1B2235 !important;
  font-weight: 400;
}
svg.svg-inline--fa.fa-magnifying-glass.mag {
  color: #9AA4B2;
}
.form-row.d-flex.flex-direction-coloum.mg6 {
  margin-bottom: 16px;
}
.col-12.wv2-table th {
  text-align: start;
  font-family: var(--heading-font);
  color: #9AA4B2;
  font-size: 12px;
  font-weight: 700;
  padding: 24px 16px;
}
.col-12.wv2-table td {
  text-align: start;
  font-family: var(--heading-font);
  color: #1B2235;
  font-size: 16px;
  padding: 24px 16px;
}
td.color-td-grn {
  color: #12B76A !important;
  font-weight: 600;
}
td.color-td-red {
  color: #F04438 !important;
  font-weight: 600;
}
.col-12.wv2-table tr {
  border-bottom: 1px solid #F2F2F2;
}
.container.wal-table-form {
  padding: 22px !important;
  border: 1px solid #EAECF0;
  border-radius: 12px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  margin-bottom: 24px;
}
.container.total-tab-sec {
  
  width: 1220px !important;
  margin: auto !important;
  padding: 0px !important;
}
.col-12.wv2-total {
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #EAECF0;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.col-12.wv2-total h3 {
  color: #1B2235;
  font-size: 20px;
  font-weight: 600;
}
.multi-data-head.d-flex h3 {
  font-size: 16px !important;
  margin: 0px !important;
  line-height: 26px !important;
}

.multi-data-head.d-flex {
  gap: 16px;
  align-items: center;
}
.multi-data-head.d-flex p {
  font-size: 20px;
  line-height: 30px;
  color: #12B76A;
  font-weight: 600;
  margin: 0px;
}
p.p2-t{
  color: #F04438 !important;
}
button.total-btn1 {
  background: transparent;
  color: #1B2235;
  font-size: 14px;
  padding: 12px 85px;
  border-radius: 8px;
  border: 1px solid #1B2235;
}
.container.total-multi-tab-sec.d-flex {
  
  max-width: 1220px !important;
  margin: auto !important;
  padding: 0px !important;
  gap: 25px;
}
.col-4.\31 st-tab{
  max-width: 390px !important;
}


.col.\32 nd-tab {
  max-width: 390px;
}
.multi-data-head .col.sec-left-main {
  min-height: 413px !important;
}
.col.left-main.m-wal.d-flex {
  border: 2px solid #EAECF0;
  justify-content: space-between;
  padding: 20px 20px;
  align-items: center;
  border-radius: 12px;
  margin-right: 24px;
  background-image: url(../public/assets/images/bsbag.svg);
  background-repeat: no-repeat;
  background-position: bottom right;
  box-shadow: 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}
.mwal-d1 {
  padding-right: 60px;
}

button.d1-btn {
  width: 221px;
  padding: 12px;
  background: #1B2235;
  color: white;
  border-radius: 8px;
  border: none;
  font-size: 14px;
}
.container.wal-cont8 {
  margin-top: 43px;
  margin-bottom: 24px;
}
.col.right-main8.m-wal.d-flex{
  border: 2px solid #EAECF0;
  justify-content: space-between;
  padding: 20px 20px;
  align-items: center;
  border-radius: 12px;
  
  box-shadow: 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}
button.d1-btn2{
  width: 221px;
  padding: 12px;
  background:transparent ;
  color: #1B2235;
  border-radius: 8px;
  border: 1px solid #1B2235;
  font-size: 14px;
}
section.fsec-dash {
  max-width: 1220px;
  margin: 30px auto;
}
input.icon-input__text-field.sec {
  padding: 6px 12px;
}
section.main-cont-comp {
  margin: auto;
 
}

.col-lg-12.main-comp-sec {
  background: #FFF;
  padding: 40px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.form-radio-check.col-4 {
  padding: 16px 20px;
  text-align: center;
  border: 2px solid #EAEBEE;
  border-radius: 8px;
  background: white;
}

.mr-1.row.d-flex {
  gap: 8px;
}

.form-radio-check.col-4 input {
  position: absolute;
  right: 10%;
}

.form-radio-check.col-4 {
  position: relative;
}

.form-radio-check.col-4 h4 {
  color: #1B2235;
  font-size: 32px;
  font-weight: 400;
}

.form-radio-check.col-4 p {
  color: #424C69;
  font-size: 20px;
  font-family: 'Hanken Grotesk';
}
.form-radio-check.col {
  padding: 16px 20px;
  text-align: center;
  border: 2px solid #EAEBEE;
  border-radius: 8px;
  background: white;
}
.form-radio-check.col input {
  position: absolute;
  right: 10%;
}

.form-radio-check.col {
  position: relative;
}

.form-radio-check.col h4 {
  color: #1B2235;
  font-size: 32px;
  font-weight: 400;
}

.form-radio-check.col p {
  color: #424C69;
  font-size: 20px;
  font-family: 'Hanken Grotesk';
}
p.amount {
  margin: 0px auto;
  color: #12B76A !important;
  font-weight: 600;
}
.col-12.not-sec h5 {
  font-size: 32px;
  color: #1B2235;
  font-weight: 800;
  font-family: 'Hanken Grotesk';
}

.col-12.not-sec {
  border-bottom: 1px solid #D5D7DE;
}
.col-lg-12.main-comp-sec h3 {
  font-size: 24px;
  line-height: 34px;
  margin: 0px;
  color: #1B2235;
  font-weight: 800;
}

.col-12.new-radio-2 h3 {
  font-size: 24px;
  line-height: 34px;
  margin: 0px;
  color: #1B2235;
  font-weight: 800;
}
.form-radio-check2.col-4 h4 {
  color: #1B2235;
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
}
.form-radio-check2.col-4 input {
  position: absolute;
  right: 10%;
}

.form-radio-check2.col-4 {
  position: relative;
}



.form-radio-check2.col-4 p {
  color: #424C69;
  font-size: 20px;
  font-family: 'Hanken Grotesk';
}
.form-radio-check2.col {
  padding: 16px 20px;
  text-align: center;
  border: 2px solid #EAEBEE;
  border-radius: 8px;
  background: white;
}
.form-radio-check2.col-4 {
  padding: 16px 20px;
  text-align: center;
  border: 2px solid #EAEBEE;
  border-radius: 8px;
  background: white;
}
.form-radio-check2.col input {
  position: absolute;
  right: 10%;
}

.form-radio-check2.col {
  position: relative;
}

.form-radio-check2.col h4 {
  color: #1B2235;
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
}

.form-radio-check2.col p {
  color: #424C69;
  font-size: 20px;
  font-family: 'Hanken Grotesk';
}
.verification-btns.d-flex {
  margin: 0px;
  padding: 40px 0px;
  justify-content: space-between;
}

button.btn.radio-btn-1.btn-primary {
  width: 49%;
  font-size: 14px;
  padding: 16px;
  color: #1B2235 !important;
  background: transparent !important;
  border: 1px solid #1B2235 !important;
  border-radius: 8px;
  font-family: 'Hanken Grotesk';
}

button.btn.radio-btn-2.btn-primary {
  width: 49%;
  font-size: 14px;
  padding: 16px;
  color: white !important;
  background: #1B2235 !important;
  border: none !important;
  border-radius: 8px;
  font-family: 'Hanken Grotesk';
}

section.main-sel-comp {
  margin: 40px auto;
  padding:  40px 40px 0px 40px;
  background: #fff;
  border-radius: 16px;
}

h3.sel-m-head {
  margin: 0px;
  padding: 0px;
  font-size: 24px;
  font-weight: 800;
  color: #1B2235;
}

section.main-sel-comp .col-lg-12 {
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.shead-box {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0px;
  border: 1px dotted #12B76A;
  border-radius: 8px;
  background: #F6FEF9;
}

p.multic-head {
  margin: 0px;
  font-size: 16px;
  color: #6C748A;
  font-weight: 400;
}

p.multic-head span {
  color: #1B2235;
  font-weight: 700;
  padding-left: 5px;
}
p.multic-head2 {
  margin: 0px;
  font-size: 16px;
  color: #6C748A;
  font-weight: 400;
}

p.multic-head2 span {
  color: #12B76A;
  font-weight: 700;
  padding-left: 5px;
}



span.input-group-text.counter-form-cont {
  padding: 12px 16px;
  font-size: 16px;
  background: #1B2235;
  color: #fff;
}
input.form-control.counter-form-input {
  text-align: center !important;
  font-family: 'Hanken Grotesk';
  font-size: 14px;
  color: #121926;
  font-weight: 500;
}
.org-text {
  color: #F79009;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.col-12.not-sec2 h5 {
  font-size: 32px;
  color: #1B2235;
  font-weight: 800;
  font-family: 'Hanken Grotesk';
}

.col-12.not-sec2 {
  border-bottom: none;
}
label.form-label.note2 {
  margin: 0px;
  padding: 16px;
  border: 2px solid #84CAFF;
  width: -webkit-fill-available;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Hanken Grotesk';
  color: #1B2235;
  margin-bottom: 0px;
  background-color: #E0F2FE;
}
.shead-box2 {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0px;
  border: 1px solid #E3E8EF;
  border-radius: 8px;
  background: #FCFCFD;
}
p.multic-head3 {
  margin: 0px;
  font-size: 14px;
  color: #1B2235;
  font-weight: 400;
}

p.multic-head3 span {
  color: #12B76A;
  font-weight: 400;
  padding-left: 5px;
}
p.multic-head-l {
  margin: 0px;
  font-size: 16px;
  color: #1B2235;
  font-weight: 700;
}
.col-12.done-col.d-flex.align-item-center.m-0 {
  padding: 0px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.row.main-card-row {
  padding: 40px;
  border-radius: 16px;
  border: 2px solid;
  margin: 100px 0px !important;
}

.col-12.done-col.d-flex.align-item-center.m-0 h4 {
  font-size: 24px;
  font-weight: 800;
  color: #1B2235;
  margin: 0px;
}

.col-12.done-col.d-flex.align-item-center.m-0 p {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #1B2235;
  margin: 0px;
}


button.done-card-btn1 {
  width: 100%;
  padding: 16px 0px;
  font-family: 'Hanken Grotesk';
  background: #1B2235;
  color: white;
  font-size: 14px;
  border-radius: 8px;
  border: none !important;
}
button.done-card-btn2 {
  width: 100%;
  padding: 16px 0px;
  font-family: 'Hanken Grotesk';
  background:transparent;
  color: #1B2235;
  font-size: 16px;
  font-weight: 700;
  border-radius: 8px;
  border: none !important;
}
.container-fluid.main-home-sec.d-flex{
  max-width: 100% !important;
  padding: 0px ;
  background:linear-gradient(103deg, #000 -1.43%, #2D3858 91.94%);
;
}
.col.right-back {
  display: flex;
  justify-content: flex-end;
}
.col-lg-7.left-back {
  background:url("../public/assets/images/Light\ accent.png");
  background-repeat: no-repeat;
}


.content-1stbanner h1 {
  font-size: 72px;
  color: white;
  font-weight: 800;
  margin: 0px;
  line-height: 80px;
}

@media (max-width: 576px) {
  .content-1stbanner h1 {
    font-size: 42px;
    color: white;
    font-weight: 800;
    margin: 0px;
    line-height: 50px;
    text-align: center;
  }
  .content-1stbanner {
    gap: 20px !important;
  }
  .content-1stbanner p{
    text-align: center;
  }
  .content-1stbanner a{
    width: 100% !important;
    justify-content: center !important;
  }
  .content-2ndbanner{
    text-align: center !important;
  }
  .content-2ndbanner h2{
    font-size: 30px !important;
    line-height: 40px !important;
  }
}

.content-1stbanner h1 span {
  color: #12B76A;
  text-decoration: underline;
  font-style: italic;
  font-weight: 600;
}

.content-1stbanner p {
  font-size: 16px;
  color: white;
  font-weight: 400;
  margin: 0px;
}

.content-1stbanner button {padding: 12px 16px;
  border-radius: 8px;
  color: white;
  background: #1B2235;
  border: none;
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: 'Hanken Grotesk';
  font-size: 14px;
  width: fit-content;
}

.content-1stbanner a {
  padding: 12px 16px;
  border-radius: 8px;
  color: white;
  background: #1B2235;
  border: none;
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: 'Hanken Grotesk';
  font-size: 14px;
  width: fit-content;
}

.col.right-back img {
  width: 100% !important;
}

.col-lg-7.left-back {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container-fluid.main-home-sec2.d-flex {
  padding:64px 0px;
  margin: 0px auto;
}

.content-2ndbanner h5 {
  margin: 0px;
  font-size: 20px;
  color: #2F69FE;
  font-weight: 600;
  font-family: 'Hanken Grotesk';
}

.content-2ndbanner h2 {
  color: #1B2235;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  font-family: 'Hanken Grotesk';
  margin: 0px;
  line-height: 56px;
}

.content-2ndbanner p {
  font-size: 16px;
  font-weight: normal;
  color: #2D3858;
  margin: 0px;
}

.content-2ndbanner button {
  padding: 12px 55px;
  border: none;
  border-radius: 8px;
  color: white;
  background: #1B2235;
  font-size: 14px;
  font-family: 'Hanken Grotesk';
  width: fit-content;
}

.content-2ndbanner a {
  padding: 12px 55px;
  border: none;
  border-radius: 8px;
  color: white;
  background: #1B2235;
  font-size: 14px;
  font-family: 'Hanken Grotesk';
  width: fit-content;
}

.content-2ndbanner {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.values-ibox-1 {
  margin: 0px;
  padding: 24px;
  border: 1px solid #EAEBEE;
  border-radius: 16px;
  box-shadow:  0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 47%;
}

@media (max-width:576px) {
  .values-ibox-1{
    width: 100%;
  }
}

.values-ibox-1 img {
  width: 40px;
  height: 40px;
}

.row.main-row-ib{
  gap: 36px;
  row-gap: 18px;
}
.col-lg-6.left-1 {
  display: flex;
  align-items: center;
}
.fourth-row .container {
  padding: 0px;
}

.property-card.d-flex {
  flex-direction: column;
  margin: 0px !important;
  padding: 32px 10px;
  gap: 16px;
 background-repeat: no-repeat;
  align-items: center;
  background-image: url(../public/assets/images/motif.png);
 background-position: bottom;
  border-radius: 16px;
  box-shadow:  0px 4px 18px 0px rgba(0, 0, 0, 0.16);
}

.property-card.d-flex img {
  width: 40px;
}

.property-card.d-flex h3 {
  color: #1B2235;
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
  padding: 0px;
  line-height: 30px;
}

.property-card.d-flex p {
  margin: 0px;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  padding: 0px 23px;
}

.cd-img img {
  width: 100% !important;
}

.five-col.mt-5.mb-lg-5.mb-sm-1.custom-card-sec {
  padding: 0px;
  margin: 0px;
  text-align: center;
}
.row.property-row {
  gap: 32px;
}
p.note-p {
  font-size: 14px !important;
  color: #29324F;
}

ul.list-group2 li {
  display: block ;
  padding-left: 0px !important;
}

ul.list-group2 li a {
  color: white;
}

ul.list-group2{
  padding-left: 0px !important;
}


.six-col.mt-5.mb-lg-5.mb-sm-1.custom-card-sec {
  text-align: center;
  padding: 0px !important;
}

.row.six-img-box-row {
  gap: 32px;
}

.col.six-col-img-box.d-flex {
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.col.six-col-img-box.d-flex img {
  height: 80px;
  width: 80px;
}

.col.six-col-img-box.d-flex h3 {
  font-size: 20px;
  color: #1B2235;
  margin: 0px;
  font-weight: 600;
}

.col.six-col-img-box.d-flex p {
  font-size: 16px;
  font-weight: 400;
  color: #1B2235;
  margin: 0px;
}
.card-body {
  padding: 16px 24px !important;
}
.card{
  border: none !important;
}
.container-fluid.home-prop-cards {
  padding: 0px;
  margin: 60px auto;
}
.inv-btn {padding: 12px 16px;
  border-radius: 8px;
  color: white;
  background: #1B2235;
  border: none;
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: 'Hanken Grotesk';
  font-size: 14px;
  width: fit-content;
}
.background-grad {
  background:  linear-gradient(103deg, #000 -1.43%, #2D3858 91.94%);
  margin-bottom: 40px;
}

.container-fluid.eight-cont {
  display: flex;
  margin: 0px auto;
  align-items: center;
}

.col.six-col-img-box2.d-flex {
  padding: 0px  50px!important;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0px;
  gap: 18px;
}

.col.six-col-img-box2.d-flex h3 {
  color: white;
  font-size: 44px;
  font-family: var(--heading-font);
  font-weight: 700;
  line-height: 55px;
  margin: 0px;
}

.col.six-col-img-box2.d-flex p {
  color: white;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Hanken Grotesk';
  margin: 0px;
}
.text-center.faq-text h2 {
  margin-bottom: 10px !important;
}

.text-center.faq-text p {
  font-weight: 600;
  color: #576079;
  font-size: 16px;
  margin-bottom: 40px;
}
.inv-btn2 {padding: 12px 26px;
  border-radius: 8px;
  color: #1B2235;
  background: transparent;
  border: 1px solid #C0C3CD;
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: 'Hanken Grotesk';
  font-size: 14px;
  width: fit-content;
}
.p-3.properties-list-faq.rounded-3.mb-3 {
  width: 100% !important;
}

.properties-list-faq .col-lg-1.col-sm-12 {
  display: flex;
  justify-content: center;
  align-items: center;
}



.properties-list-faq .col-lg-3.col-sm-12.p-0 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.properties-list-faq .col-lg-3.col-sm-12.p-0 button {
  background: #1B2235;
  color: white;
  border-radius: 8px;
  padding: 12px 50px;
  font-size: 14px;
  font-family: 'Hanken Grotesk';
}

.p-3.properties-list-faq.rounded-3.mb-3 {
  padding: 24px;
  border: 1px solid #D5D7DE;
  border-radius: 16px;
  background: rgba(27, 34, 53, 0.00);
}
.container.last-faq-sec {
  margin-bottom: 83px;
}
footer.pt-3.pb-3{
  background-image: url(../public/assets/images/Light\ accent2.png);
  background-repeat: no-repeat;
  background-position: bottom left;
}
.footer-wrap{
  background: linear-gradient(103deg, #000 -1.43%, #2D3858 91.94%);
}

.accordion-button:not(.collapsed) {
  gap: 8px !important;
}
.result-text.d-flex.spb-btn {
  justify-content: space-between;
  padding: 0px 0px 16px 0px;
  align-items: baseline;
}

button.mp-btn {
  display: flex;
  gap: 16px;
  padding: 12px 16px;
  font-size: 14px;
  color: #1B2235;
  background: transparent;
  border-radius: 8px;
  border: 1px solid #D5D7DE;
}
p.card-text.w-img {
  font-size: 14px;
  color: #1B2235;
  font-weight: 400;
  margin: 0px;
}

p.card-text.w-img span {
  font-size: 16px;
  font-weight: 700;
}
button.global-ct-sec {
  background: transparent;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 40px;
  line-height: 16px;
  border: 1px solid #12B76A;
  color: #12B76A;
}

.\32 btn-grp {
  display: flex;
  justify-content: space-between;
  padding: 16px 0 16px 0 !important
}
.container.main-mpv2-cont {
  padding: 0px;
  margin: 0px;
  border: 1px solid #D5D7DE;
  border-radius: 16px;
}

.row.mvp-r1 {
  border-bottom: 1px solid #D5D7DE;
  padding: 24px;
  width: 100%;
  margin: auto;
}

.col-8.mv2-1sthead {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.col-8.mv2-1sthead h3 {
  font-size: 24px;
  font-weight: 800;
  color: #1B2235;
}
.col.mv2-btn button {
  color: #1B2235;
  font-size: 14px;
  background: transparent;
  padding: 12px 16px;
  border-radius: 16px;
  border: none;
  display: flex;
  gap: 8px;
}
.col-12.mv2-btn a {
  color: #1B2235;
  font-size: 14px;
  background: transparent;
  padding: 12px 16px;
  border-radius: 16px;
  border: none;
  display: flex;
  gap: 8px;
}
.col.mtable-mconl {
  padding: 24px;
}
.mv2-tab-main th{
  text-align: start;
  font-size: 12px;
  font-family: var(--heading-font);
  text-transform: uppercase;
  color: #9AA4B2;
  font-weight: 700;
}
table.table.mv2-tab-main td {
  text-align: start;
  font-size: 16px;
  color: #1B2235;
  font-family: 'Hanken Grotesk';
  font-weight: 400;
  vertical-align: baseline;
}
td.img-text-td2 img {
  width: 24px;

}
.container.p-0.main-wraper-mail.d-flex {
  flex-direction: column;
}

.container.p-0.main-wraper-mail.d-flex img {
  margin: 40px auto;
  width: fit-content;
}
.col-6.email-sec1l {
  padding: 10px 10px 0px 10px;
  background: lightgray;
  margin-right: 10px;
}
.col.email-sec12{
  padding: 10px 10px 0px 10px;
  background: lightgray;
 
}


.row.mail-row1 {
  padding: 0px 20px !important;
}

.col-6.email-sec1l h2 {
  font-size: 24px;
  line-height: 32px;
  color: #1B2235;
  font-weight: 700;
}
.col.email-sec12 h2 {
  font-size: 24px;
  line-height: 32px;
  color: #1B2235;
  font-weight: 700;
}

.head-w-text h4 {
  color: #1B2235;
  font-size: 20px;
  font-weight: 600;
}

.head-w-text p {
  font-size: 18px;
  margin: 0px;
}

p.bl-text {
  color: blue;
  text-decoration: underline;
  margin: 0px;
}
p.blue-text {
  color: blue;
  text-decoration: none;
  margin: 0px;
}
p.gray-text {
  color: #686b70;
  font-weight: 500;
  margin: 0px;
  font-size: 16px;
}

.head-w-text {
  padding-bottom: 20px;
}




.col-6.image-div-mail img {
  margin: 0px !important;
}

.col-6.cont-area {
  padding: 64px 24px 0px 24px; 
  text-align: center;
  }

.col-6.cont-area p {
  color: white;
  font-size: 20px;
  font-family: 'Hanken Grotesk';
  margin: 0 0 16px 0;
}

.col-6.cont-area h2 {
  color: white;
  font-size: 27px;
  font-weight: 700;
  line-height: 34px;
  margin: 0 0 16px 0;
}
.col-6.image-div-mail{
  padding: 33px 42px;
}
.row2.d-flex {
  margin-top: 40px;
  background: linear-gradient(103deg, #000 -1.43%, #2D3858 91.94%);
}
.col-6.cont-area button {
  background: #1B2235;
  padding: 12px 49px;
  color: white;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Hanken Grotesk';
  border: none;
  border-radius: 8px;
}
.row.r4-text {
  margin: 40px 0px;
  border-bottom: 2px solid black;
}

p.solo-text {
  font-size: 16px;
  color: black;
  font-weight: 400;
  line-height: 26px;
  padding: 0px 20px;
}
.row.r5-foot {
  text-align: center;
}

.row.r5-foot p {
  font-size: 16px;
  color: #1B2235;
  margin: 0px;
  font-weight: 400;
}
.social-icons ul {
  list-style: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 26px;
  margin: 0px;
  padding: 0px;
}
.social-icons{
  height: 80px;
}
p.address-mail.text-center {
  font-size: 13px;
  color: black;
}
button.unsub-btn {
  border: none;
  color: black;
  text-decoration: underline;
  font-size: 12px;
  margin-top: 20px;
  background: white;
}
.carousel-indicators button {
  height: 6px !important;
  width: 6px !important;
  border: none !important;
  border-radius: 40px;
}

.carousel-indicators button.active {
  height: 4px !important;
  width: 4px !important;
  transform: translateY(0px);
  margin: 0px 10px;
}

.carousel-indicators button.active:after {
  content: '';
  width: 17px;
  border: 2px solid white;
  position: absolute;
  bottom: -7px;
  left: -6px;
  height: 17px;
  border-radius: 40px;
}
.col-12.not-sec {
  padding: 0px;
}
.row.main-card-row {
  border: none !important;
}
.inline-svg-icons.d-flex {
  gap: 12px;
}

button.inline-svg-icons-btn1 {
  border: none;
  border-radius: 40px;
  height: 36px;
  width: 36px;
}

.result-text2.d-flex.spb-btn {
  justify-content: space-between;
  padding: 16px 0px 0px 0px;
  align-items: baseline;
}
.result-text2 p {
  font-size: 14px !important;
  line-height: 24px !important;
  font-family: var(--heading-font);
  color: #697586;
  font-weight: 400;
  margin: 0px;
}
.container-fluid.auth-header {
  display: flex;
  justify-content: space-between !important;
}

.container-fluid.auth-header div#navbarSupportedContent {
  justify-content: end;
}
.auth-nav {
  display: block !important;
}
.dash-head{
  display: block !important;
}
.row.header-m-main.m-0 {
  padding: 0px !important;
}

section.main-grid2.d-flex.comp-profile {
  max-width: 550px !important;
  padding: 40px;
}


.row.comp-profile-main-row {
  margin: 0px;
  padding: 0px;
}
.middle-sec2.p-c-s h2 {
  margin: 0px;
}

.middle-sec2.p-c-s p {
  font-weight: 500;
  font-size: 14px;
  margin: 0px;
}

.middle-sec2.p-c-s {
  padding: 0px 0px 16px 0px;
  border-bottom: 1px solid #D5D7DE;
}
.custom-checkbox.component-sec label.container {
  flex-direction: column;
  line-height: 20px;
  height: 108px !important;
}
.card-inner3.invi.my-4.yellow {
  border: 1px solid #f8aa43 !important;
}
.col.invest-card-comp {
  max-width: 390px !important;
}
li.slick-active{
  content: '';
width: 17px;
border: 2px solid white;
height: 17px;
border-radius: 40px;
}
.slick-dots{
  bottom: 10px !important;
  color: #fff;
}
.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 16px;
  position: absolute;
  top: -2px !important;
  left: -2px !important;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: #fff !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: auto;
  height: auto;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.carousel-indicars {
  display: none;
}
.card-inner3.invi.my-4.yellow {
  border: 1px solid #f8aa43 !important;
}
.global-ct4 {
  font-size: 12px !important;
  line-height: 16px !important;
  padding: 4px 8px !important;
  border-radius: 32px;
  border: 1px solid #F79009;
  margin-bottom: 8px;
  color: #F79009;
  width: fit-content;
}
/*price-component*/



.container.main-price-list {
  max-width: 290px;
  background: white;
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

h3.price-list-heading {
  font-size: 20px;
  font-weight: 600;
  color: #1B2235;
  line-height: 30px;
  margin: 0px;
}

.two-btn-last-grp {
  padding-top: 16px;
  border-top: 1px solid #D5D7DE;
  display: flex;
  justify-content: space-between;
}

button.norm-btn {
  font-size: 14px;
  font-weight: 500;
  color: #1B2235;
  width: 49%;
  padding: 12px;
  border: none;
  background: transparent;
  font-family: var(--heading-font);
}

button.not-norm-btn {
  font-size: 14px;
  font-weight: 500;
  color: white;
  background: #1B2235;
  border: none;
  width: 49%;
  padding: 12px;
  border-radius: 8px;
  font-family: var(--heading-font);
}
label.form-check-label.rpl {
  font-size: 16px;
  color: #1B2235;
    font-family: var(--heading-font);
}

label.form-check-label.rpl span {
  color: #ABAFBC !important;
  padding-left: 8px;
    font-family: var(--heading-font);
}
.radio-prce-list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.form-check.main-lr .form-check-input[type=radio] {
  border-radius: 2px !important;
}

.form-check.main-lr .form-check-input:checked[type=radio] {
  background: #1B2235;
  border: 1px solid #697586;
}
.form-check.main-lr{
    padding: 0px;
    border: none;
    border-radius: 0px;
}

/*filter-component*/



.container.main-filter-list {
  max-width: 445px;
  background: white;
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
h3.filter-list-heading {
  font-size: 20px;
  font-family: inter;
  color: #221E22;
  font-weight: 700;
  margin: 0px;
}
/*dropdownmenus*/
.ndb1 {
  padding: 12px 12px !important;
  background: transparent;
  border: 1px solid #D5D7DE;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  width: 155px;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ndb1.dropdown-toggle::after {
    font-size: 20px;
}
.ndb1.btn-check:checked+.btn,  .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    background: transparent !important;
    border: none !important;
    color: black !important;
}
.ndb1.btn:hover{
    background: transparent;
    border: 1px solid #D5D7DE;
    color:black ;

}
.ndb2 {
  background: transparent;
  border: none;
  padding: 0px !important;
  margin: 0px !important;
}

.ndb2 img {
  border: none !important;
  height: 16px;
  width: 16px;
}
.ndb2.btn-check:checked+.btn,  .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
  background:transparent !important;
  border: none !important;
  color: #000000;
}
.ndb2.btn:hover{
  background: transparent;
  border: none;
  color:black ;

}

.bg-new {
  background-image: url(../public/assets/images/homebg.png);
  background-size: 720px 671px !important;
  background-repeat: no-repeat;
  background-position: bottom right;}
  .tabs-section.d-flex.justify-content-start.pdp-page {
    border: none !important;
    padding: 0px 0px 10px 0px;
}
h1.f-tab-head{
  font-size: 32px;
  font-weight: 600;
  color: #1B2235;
  padding-top: 20px;
}
h3.map-head{
  font-size: 32px;
  font-weight: 600;
  color: #1B2235;
  padding-top: 20px;
}
/*Range*/
#search-bar input[type="range"]::-webkit-slider-runnable-track {
  height: 4px;
  cursor: pointer;

  background: #03a9f4;
  border-radius: 25px;
}

#search-bar input[type="range"]::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

#search-bar input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #03a9f4;
}

#search-bar .range-wrap {
  padding: 4px 6px 0px 6px;
  position: relative;
  width: 694px;
}

#search-bar .range-value {
  position: absolute;
  bottom: -35%;
}

#search-bar .range-value span {
  width: fit-content;
  height: auto;
  line-height: 26px;
  text-align: center;
  background: #171C2C;
  color: #fff;
  font-size: 16px;
  font-family: var(--heading-font);
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 6px;
  padding: 4px 16px;
}

#search-bar .range-value span:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid #171C2C;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: -25%;
  left: 50%;
  margin-left: -5px;
  margin-top: -1px;
  transform: rotate(180deg);
}
input#range-km {
    width: -webkit-fill-available;
}
.range-wrap.two {width: 910px !important;}

.row.artical2.d-flex {
  padding: 0px !important;
}
.nfaq-tab .nav-link.active,
.nfaq-tab .nav-link.active:focus,
.nfaq-tab .nav-link.active:hover {
  background-color:transparent;
  color: #2F69FE; /* Change the text color to white for better visibility */
  border-bottom:2px solid #2F69FE; /* Optional: Change the border color */
  width: fit-content  !important;
 padding:8px 0px !important;
  border-radius: 0px;
}
.col-lg-3.col-sm-12.rounded-4.shadow-lg {
  width: 31%;
}
.img-box-cont img{
  width: 365px;
  height: 204px;
  object-fit: cover;
}
.link-container {
  display: flex;
  gap: 20px; /* Space between the links */
  justify-content: center;
}

.link {
  text-decoration: none;
  color: #ffffff;
  font-size: 12px;
}

.link:hover {
  color: #007bff;
}

.articles-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

/*.card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  width: 300px;
  transition: transform 0.2s;
}*/

.card h3 {
  margin-top: 0;
}

.load-more {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.load-more:hover {
  background-color: #0056b3;
}

@media (min-width: 992px) {
  .carousel-item {
    display: none;
  }
  .property-card {
    width: 400px;
    height: 550px;
  }
  .property-card.d-flex{
    margin: auto !important;
  }
}

@media (max-width: 991.98px) {
  .property-row {
    display: none;
  }
  .property-card {
    width: 400px;
    height: 550px;
  }
  .property-card.d-flex{
    margin: auto !important;
  }
}

@media (max-width: 576px) {
  .property-card {
    width: auto;
  }
  .card-inner3 {
    padding-left: 5px !important;
    padding-right: 0px !important;
  }
  .men .image-cropper{
    width: 240px;
  }
  section .tabs-section{
    padding: 20px 0 0 0;
  }
  .nav-tabs-container .nav{
    flex-wrap: nowrap;
  }
}

@media (max-width: 575.98px) {
  .property-card {
    width: auto;
  }
  .card-inner3 {
    padding-left: 5px !important;
    padding-right: 0px !important;
  }
  .men .image-cropper{
    width: 240px;
  }
  section .tabs-section{
    padding: 20px 0 0 0;
  }
  .nav-tabs-container .nav{
    flex-wrap: nowrap;
  }
}

.nav-tabs-container {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.nav-tabs {
  white-space: nowrap;
}

.table-responsive {
  overflow-x: auto; /* Ensure horizontal scrolling if needed */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for mobile */
}

.table {
  width: 100%; /* Make sure the table takes full width */
}

.property-row .buy-btn {
  visibility: hidden; /* Hide the button by default */
  opacity: 0; /* Make it invisible */
  transition: opacity 0.3s ease-in-out; /* Smooth transition */
}

.property-row:hover .buy-btn {
  visibility: visible; /* Show the button on hover */
  opacity: 1; /* Make it visible */
}

/* Always show rows on mobile */
@media (max-width: 576px) {
  .table-striped tbody tr {
    display: table-row !important; /* Ensure rows are visible */
  }
  .property-row .buy-btn {
    visibility: visible; /* Make "Buy" button always visible in mobile mode */
    opacity: 1;
  }
  .datehcolumn{
    display: none;
  }
  .datecolumn{
    display: none;
  }
  .sellerhcolumn{
    display: none;
  }
  .sellercolumn{
    display: none;
  }
}

.stat .css-rccht4{
  width: 100% !important;
  height: 100% !important;
}

.navlogo{
  height: 48px;
}

.framexp {
  background-color: var(--basewhite);
  border-radius: 28px;
  height: 46px;
  overflow: hidden;
  position: relative;
  width: 108px;
}

.framexp .containerxp {
  align-items: center;
  display: inline-flex;
  gap: 9.19px;
  justify-content: center;
  left: 7px;
  position: relative;
  top: 7px;
}

.framexp .green-coin {
  height: 31.6px;
  position: relative;
  width: 31.6px;
}

.framexp .balance {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.17px;
  padding: 2.3px 0px;
  position: relative;
}

.framexp .text-wrapper {
  color: var(--graymain-200) !important;
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 10.3px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 4.6px;
  margin-top: -0.29px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.framexp .div {
  align-items: center;
  align-self: stretch;
  border-radius: 4.6px;
  display: flex;
  flex: 0 0 auto;
  gap: 2.3px;
  position: relative;
  width: 100%;
}

.framexp .image {
  height: 11.95px;
  position: relative;
  width: 7.47px;
}

.framexp .text-wrapper-2 {
  color: var(--graymain-900) !important;
  font-family: "Hanken Grotesk", Helvetica;
  font-size: 8.6px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 6.9px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.framexp .frame-wrapper {
  background-color: var(--graymain-10);
  border-radius: 1.15px;
  height: 1.15px;
  overflow: hidden;
  position: relative;
  width: 33.9px;
}

.framexp .div-2 {
  background-color: var(--ecogreen-400) !important;
  border-radius: 1.15px;
  height: 1px;
  width: 21px;
}

